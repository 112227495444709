import { useMemo } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import Default from "./Default";
import Large from "./Large";
import Password from "./Password";
import Search from "./Search";
import Simple from "./Simple";

const TYPE = {
  simple: Simple,
  large: Large,
  search: Search,
  password: Password,
  default: Default,
};

const Input = ({ type, ...props }) => {
  const View = useMemo(() => get(TYPE, type, TYPE.default), [type]);
  return <View {...props} />;
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPE)),
};

export default Input;
