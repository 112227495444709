import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";
import { PRODUCT_TYPES } from "utils/constants";

const View = ({ onSubmit, cancel, initialValues }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.PRODUCT);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
          <Header
            key={"header"}
            title={CREATE.PROCESS.HEADER.TITLE}
            subtitle={CREATE.PROCESS.HEADER.ACTION}
            actions={[
              {
                text: CREATE.PROCESS.HEADER.DISCARD,
                onClick: cancel,
                variant: "outlined",
              },
              {
                disabled: !(values.name && values.type),
                text: CREATE.PROCESS.HEADER.SAVE,
                onClick: handleSubmit,
              },
            ]}
          />,
          <Row gutter={[32]} key={"content"}>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 20, offset: 2, order: 1 }}
              lg={{ span: 14, offset: 5, order: 2 }}
            >
              <div className="inputs--container">
                <div className="inputs--container__header">
                  <span>{FORM.GENERAL_INFO}</span>
                </div>
                <Form type={"vertical"}>
                  <div className="field--item">
                    <label>{FORM.NAME}</label>
                    <Input
                      placeholder={FORM.NAME}
                      {...bindInputProps({
                        name: "name",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>
                  <div className="field--item">
                    <label>{FORM.TYPE}</label>
                    <Select
                      placeholder={FORM.TYPE}
                      items={Object.values(PRODUCT_TYPES)}
                      {...bindInputProps({
                        name: "type",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>
                </Form>
              </div>
            </Col>
          </Row>,
        ]}
      </Formik>
    </Container>
  );
};

export default View;
