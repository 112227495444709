import { useMutation } from "@apollo/client";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { SiOpensourceinitiative } from "react-icons/si";
import Button from "shared/components/Button";
import Drawer from "shared/components/Drawer";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { useWallet } from "shared/contexts/Wallet";
import { META, SCREENS, WALLET_PROVIDERS } from "utils/constants";

const Finalize = () => {
  const navigate = useNavigate();
  const { wallet } = useWallet();
  const { search } = useLocation();
  const { flow } = qs.parse(search, { ignoreQueryPrefix: true });
  const [executeFlow] = useMutation(graphql.mutations.EXECUTE_FLOW, {
    onCompleted: () =>
      navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.SUCCESS.path),
    onError: () => {},
  });

  const connect = async () => {
    const { addresses } = await wallet.list(
      META[WALLET_PROVIDERS.XVERSE].PURPOSES.ORDINALS
    );
    const [address] = addresses;
    return executeFlow({
      variables: {
        where: { id: flow.id },
        data: {
          state: "finalize-contract",
          product: { id: "14" },
          destination: {
            ordinal: {
              value: address.address,
              publicKey: address.publicKey,
            },
          },
        },
      },
    });
  };

  return (
    <Drawer title={"Connect your BTC Wallet"}>
      <div className="center__area">
        <SiOpensourceinitiative size={64} color={COLORS.C_SECOND} />
        <div className="content">
          <h3>Connect your BTC Wallet</h3>
          <p>
            Connect your Ordinal Wallet to be able to sign the vesting contract
          </p>
        </div>
        <div className="d-flex justify--between align--center">
          <Button mode={"secondary"} onClick={connect}>
            Connect source Address
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Finalize;
