import { useMutation } from "@apollo/client";
import axios from "axios";
import { isEmpty, isNil, startCase } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import Drawer from "shared/components/Drawer";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import tickers from "./Items/tickers";
import Row from "shared/components/Row";
import Col from "shared/components/Col";

const Lock = () => {
  const [data, setData] = useState({});
  const [utxos, setUtxos] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.LIST.path, { state });

  const [executeFlow] = useMutation(graphql.mutations.EXECUTE_FLOW, {
    onCompleted: (data) => {
      navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.NOTIFY.path, {
        state: { ...state, flow: { id: data.executeFlow.id } },
      });
    },
    onError: () => {},
  });

  const load = async () => {
    if (data.emission && data.amount && data.asset) {
      const { emission, amount } = data;
      const countLocks = amount / ((amount * emission) / 100);
      const url = `https://mempool.space/testnet/api/address/${state.bitcoin.address.address}/utxo`;
      //const url = `${process.env.REACT_APP_ORDINALS_API}/address/bc1p527kv6mrq2sn5l7ukapq4q4a4puqfd9jsm7fv6r06c5726kyk57qnfvs4e`;
      //const url = `https://api.hiro.so/ordinals/v1/brc-20/activity?ticker=${asset}&limit=${countLocks}&operation=transfer`
      const { data: txs } = await axios(url);
      setUtxos(txs.slice(0, countLocks));
    }
  };

  const submit = () => {
    const {
      bitcoin: { address },
    } = state;
    const {
      investorName,
      emission,
      amount,
      every = 1,
      period = "w",
      asset,
      activationDate = moment(),
    } = data;
    const countLocks = amount / ((amount * emission) / 100);
    const oneLockAmount = amount / countLocks;
    const locks = Array.from({ length: countLocks }, (_, index) => {
      return {
        amount: oneLockAmount,
        expiry: moment(activationDate)
          .add(every + index, period)
          .format(),
        locker: {
          cardinal: {
            value: address.address,
            publicKey: address.publicKey,
          },
        },
      };
    });
    return executeFlow({
      variables: {
        where: {},
        data: {
          fee: 300,
          locks: [locks[0]],
          product: state.product,
          meta: { description: investorName, asset },
        },
      },
    });
  };

  return (
    <Drawer title={"Create New Calendar Schedule"} onClose={cancel}>
      <Input
        label={"Investor Name"}
        className={"m-bottom-16"}
        onChange={(_) => {
          return setData((__) => ({ ...__, investorName: _.target.value }));
        }}
      />
      <div className="d-flex gap-16">
        <Input
          label={"Total Locked Amount"}
          className={"m-bottom-16"}
          onChange={(_) =>
            setData((__) => ({
              ...__,
              amount: _.target.value,
            }))
          }
        />
        <Select
          label={"Asset"}
          items={tickers.map((tick) => ({
            label: startCase(tick.ticker),
            value: tick.ticker,
          }))}
          className={"m-bottom-16"}
          onChange={(_) =>
            setData((__) => ({
              ...__,
              asset: _,
            }))
          }
        />
      </div>
      <div className="d-flex gap-16">
        <Input label={"Every"} />
        <Select
          label={"Period"}
          items={[
            { label: "Day", value: "d" },
            { label: "Week", value: "w" },
            { label: "Month", value: "m" },
          ]}
          onChange={(_) =>
            setData((__) => ({
              ...__,
              period: _,
            }))
          }
        />
        <Input
          label={"Emission %"}
          onChange={(_) =>
            setData((__) => ({
              ...__,
              emission: _.target.value,
            }))
          }
        />
      </div>

      <div className="address__wrapper">
        <Row gap={8}>
          {utxos.map((utxo) => (
            <Col key={utxo.txid} xs={12}>
              <div className="item">{utxo.txid}</div>
            </Col>
          ))}
        </Row>
      </div>

      <div className="d-flex justify--between align--center m-top-30">
        <Button mode={"default"} onClick={cancel}>
          Back
        </Button>
        <div className="d-flex align--center">
          <Button mode={"primary"} onClick={() => load()}>
            Load Inscriptions
          </Button>
          <Button
            mode={"secondary"}
            onClick={submit}
            disabled={
              isEmpty(utxos) ||
              (isNil(data.amount) && isNil(data.asset) && isNil(data.emission))
            }
          >
            Continue
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Lock;
