import { gql } from "@apollo/client";

export const LIMIT_ITEMS = gql`
  query LimitItems($where: LimitItemWhereInput, $skip: Int, $take: Int) {
    limitItems(where: $where, skip: $skip, take: $take) {
      data {
        id
        value
        limit {
          id
          type
        }
      }
      count
    }
  }
`;

export const LIMIT_ITEM = gql`
  query LimitItem($where: LimitItemWhereInput) {
    limitItem(where: $where) {
      id
      value
      limit {
        id
        type
      }
    }
  }
`;

const limitItem = { LIMIT_ITEMS, LIMIT_ITEM };

export default limitItem;
