import React from "react";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Position,
  MarkerType,
} from "reactflow";

import "reactflow/dist/style.css";
import "./custom.css";

const initialNodes = [
  {
    id: "1",
    position: { x: 0, y: 0 },
    data: { label: <span>{"Amount: {YOUR_AMOUNT}"}</span> },
    sourcePosition: Position.Right,
  },
  {
    id: "2",
    position: { x: 0, y: 200 },
    data: { label: <span>{"Fee: {YOUR_FEES}"}</span> },
    sourcePosition: Position.Right,
  },
  {
    id: "3",
    position: { x: 400, y: 100 },
    data: { label: "3" },
    targetPosition: Position.Left,
  },
  {
    id: "4",
    position: { x: 400, y: 300 },
    data: { label: "4" },
    targetPosition: Position.Top,
  },
  {
    id: "5",
    position: { x: 0, y: 500 },
    data: { label: <span>{"VAULT: {LOCK_VAULT}"}</span> },
    sourcePosition: Position.Right,
    targetPosition: Position.Top,
  },
  {
    id: "6",
    position: { x: 0, y: 700 },
    data: { label: <span>{"Fee: {YOUR_FEES}"}</span> },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "7",
    position: { x: 400, y: 600 },
    data: { label: "7" },
    targetPosition: Position.Left,
  },
  {
    id: "8",
    position: { x: 400, y: 800 },
    data: { label: "8" },
    targetPosition: Position.Top,
  },
];

const initialEdges = [
  {
    id: "e1-3",
    source: "1",
    target: "3",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "lock",
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "broadcast-lock",
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    animated: true,
    markerStart: {
      type: MarkerType.ArrowClosed,
    },
    label: "spends from previously broadcasted vault",
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "unlock",
  },
  {
    id: "e6-7",
    source: "6",
    target: "7",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e7-8",
    source: "7",
    target: "8",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "broadcast-unlock",
  },
];

const LayoutFlow = () => {
  const [nodes, _, onNodesChange] = useNodesState(initialNodes);
  const [edges, __, onEdgesChange] = useEdgesState(initialEdges);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    />
  );
};

const Flow = () => {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
};

export default Flow;
