import React from "react";

const Col = ({ children, xs, sm, md, lg, xl }) => {
  const colClasses = [
    xs ? `col--xs-${xs}` : '',
    sm ? `col--sm-${sm}` : '',
    md ? `col--md-${md}` : '',
    lg ? `col--lg-${lg}` : '',
    xl ? `col--xl-${xl}` : '',
  ].join(' ').trim();

  return <div className={colClasses}>{children}</div>;
};

export default Col;
