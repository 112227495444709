import PropTypes from "prop-types";

const MenuItem = ({ icon, label }) => {
  return (
    <span className="menu--item">
      {icon && <img src={icon} alt="" />}
      <span>{label}</span>
    </span>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
};

MenuItem.defaultProps = {
  label: "menu",
};

export default MenuItem;
