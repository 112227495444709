import { useQuery } from "@apollo/client";
import { get, isNil } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Loading";
import graphql from "utils/api/graphql";
import { PRODUCT_TYPES, SCREENS } from "utils/constants";

function Trace() {
  const navigate = useNavigate();
  const { data } = useQuery(graphql.queries.PRODUCT, {
    variables: {
      where: {
        type: PRODUCT_TYPES.VESTING.value,
      },
    },
  });

  useEffect(() => {
    const applyTrace = () => {
      const product = get(data, "product");
      if (!isNil(product))
        return navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.LIST.path, {
          state: { product: { id: product.id } },
        });
    };
    return applyTrace();
  }, [data, navigate]);

  return <Loading />;
}

export default Trace;
