import { Input as InputField } from "antd";
import PropTypes from "prop-types";
import "./style.scss";

const Input = ({ placeholder, errors, touched, ...rest }) => {
  return (
    <div className={"input"}>
      <p className={"input-placeholder"}>{placeholder}</p>
      <InputField
        className={`${touched && errors && "input-input-error"} input-input`}
        placeholder={placeholder}
        {...rest}
      />
      {touched && <small className={"input-error"}>{errors}</small>}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  placeholder: "",
};

export default Input;
