import { gql } from "@apollo/client";

export const WEBHOOKS = gql`
  query Webhooks($where: WebhookWhereInput) {
    webhooks(where: $where) {
      data {
        id
        name
        description
        url
        organization {
          id
          name
        }
      }
      count
    }
  }
`;

export const WEBHOOK = gql`
  query Webhook($where: WebhookWhereInput) {
    webhook(where: $where) {
      id
      name
      description
      url
      organization {
        id
        name
      }
    }
  }
`;

const webhook = { WEBHOOKS, WEBHOOK };

export default webhook;
