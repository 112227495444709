import subscriptions from "utils/api/graphql/subscriptions";
import { names, SUBSCRIPTION_EVENT_TYPES } from "./constants";

const config = {
  [names.POSITIONS]: [
    {
      subscriptionQuery: subscriptions.POSITION_ADDED,
      type: SUBSCRIPTION_EVENT_TYPES.ADD,
    },
    {
      subscriptionQuery: subscriptions.POSITION_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
  ],
  [names.ACCOUNTS.BALANCE]: [
    {
      subscriptionQuery: subscriptions.BALANCE_UPDATED,
      type: SUBSCRIPTION_EVENT_TYPES.UPDATE,
    },
  ],
};

export default config;
