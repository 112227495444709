import { Tag } from "antd";
import { FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import logo from "shared/assets/images/logo.svg";
import Profile from "shared/components/Profile";
import { COLORS } from "shared/style/colors";

const Sidebar = ({ isOpen, onClick, menu }) => {
  return (
    <>
      <div className={`sidebar__area ${isOpen ? "show" : ""}`}>
        <div className="sidebar__area--head">
          <img src={logo} alt="" />
          <Tag color="orange">testnet</Tag>
        </div>
        <div className="sidebar__area--body">
          <ul className="sidebar__area--menu">
            {menu.map((item) => (
              <li key={item.id}>
                <NavLink to={item.to}>{item.element}</NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="sidebar__area--bottom">
          <Profile avatar={<FiUser size={24} color={COLORS.C_MAIN} />} title={'Profile'} />
        </div>
      </div>
      <div
        className={`sidebar--overlay ${isOpen === true ? "active" : ""}`}
        onClick={onClick}
      ></div>
    </>
  );
};

export default Sidebar;
