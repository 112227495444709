import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "utils/constants";
import View from "./View";
import graphql from "utils/api/graphql";
import { faker } from "@faker-js/faker";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.USERS.LIST);
  const [signUserUp] = useMutation(graphql.mutations.SIGN_USER_UP, {
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = () => {
    return signUserUp({
      variables: {
        data: {
          lastname: faker.name.lastName(),
          firstname: faker.name.firstName(),
          birthDate: faker.date.past(),
          gender: "M",
          email: faker.internet.email(),
          phone: faker.phone.number(),
          meta: { type: "admin" },
          organization: { id: 1 },
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
