export const names = {
  POSITIONS: "POSITIONS",
  ACCOUNTS: { BALANCE: "BALANCE" },
};

export const SUBSCRIPTION_EVENT_TYPES = {
  ADD: "add",
  UPDATE: "update",
  DELETE: "delete",
};
