import gql from "graphql-tag";

export const CREATE_LIMIT = gql`
  mutation CreateLimit($data: LimitDataInput) {
    createLimit(data: $data) {
      id
    }
  }
`;

export const UPDATE_LIMIT = gql`
  mutation UpdateLimit($where: LimitWhereInput, $data: LimitDataInput) {
    updateLimit(where: $where, data: $data) {
      id
    }
  }
`;

const limit = { CREATE_LIMIT, UPDATE_LIMIT };

export default limit;
