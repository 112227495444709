import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST);
  const [updateOrganization] = useMutation(
    graphql.mutations.UPDATE_ORGANIZATION,
    {
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST);
      },
      onError: () => {},
    }
  );
  const { data, loading, error } = useQuery(graphql.queries.ORGANIZATION, {
    variables: { where: { id } },
  });

  const onSubmit = (data) => {
    return updateOrganization({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View
      onSubmit={onSubmit}
      organization={data.organization}
      cancel={cancel}
    />
  );
};

export default Detail;
