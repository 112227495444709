import { useQuery } from "@apollo/client";
import { Col, Row, Table, Tag } from "antd";
import cuid from "cuid";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";

function List() {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.USER);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.USERS, {
    variables: { where: {} },
  });

  const columns = [
    {
      title: LIST.ID,
      key: "id",
      dataIndex: "id",
    },
    {
      title: LIST.FULL_NAME,
      key: "fuulName",
      render: (_, __) => (
        <Tag>{`${startCase(__.firstname)} ${startCase(__.lastname)}`}</Tag>
      ),
    },
    {
      title: LIST.ROLE,
      key: "role",
      render: (_, __) => <Tag>{`${startCase(__.meta.type)}`}</Tag>,
    },
    {
      title: LIST.ACTIONS,
      key: "actions",
      render: (_, __) => {
        return (
          <Row gutter={8}>
            <Col span={4}>
              <AiFillEye
                size={18}
                onClick={() =>
                  navigate(
                    `${SCREENS.PRIVATE.ORGANIZATION.USERS.DETAIL}/${__.id}`
                  )
                }
              />
            </Col>
            <Col span={4}>
              <AiOutlineUser
                size={18}
                onClick={() =>
                  navigate(
                    `${SCREENS.PRIVATE.ORGANIZATION.USERS.DETAIL}/${__.id}`
                  )
                }
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  if (loading || error) return <Loading />;

  const { users } = data;
  return (
    <div className="page__area">
      <Container>
        <Header
          key={cuid()}
          title={LIST.HEADER.TITLE}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[
            {
              text: LIST.HEADER.NEW,
              onClick: () =>
                navigate(SCREENS.PRIVATE.ORGANIZATION.USERS.CREATE),
            },
          ]}
        />
        <Table
          className="custom__table"
          size="small"
          dataSource={users.data}
          columns={columns}
          rowKey={"id"}
        />
      </Container>
    </div>
  );
}

export default List;
