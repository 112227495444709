import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreContext } from "shared/contexts/store";
import { SCREENS } from "utils/constants";

const authRoutes = Object.values(SCREENS.AUTH).map((_) => _.path);

const PublicRoute = ({ component }) => {
  const { accessToken } = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const goToHome = () => {
      if (accessToken && authRoutes.includes(location.pathname))
        return navigate(SCREENS.PRIVATE.HOME);
    };
    return goToHome();
  }, [accessToken, location.pathname, navigate]);

  if (accessToken && authRoutes.includes(location.pathname)) return "Loading";

  return component;
};
export default PublicRoute;
