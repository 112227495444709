import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Container from "shared/components/Container";
import { AiOutlineDeploymentUnit, AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import cuid from "cuid";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { DATE_TIME_FORMAT, PRODUCT_TYPES, SCREENS } from "utils/constants";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDrawers } from "shared/contexts/Drawers";
import Drawers from "./Drawers";
import { FiPlus, FiSettings } from "react-icons/fi";
import { FaCaretRight } from "react-icons/fa";

const ACTIONS = {
  [PRODUCT_TYPES.ORDINALS_LENDER.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.BTC_LENDER.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={__onClick}
      icon={<AiFillSetting />}
    />,
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.BTC_STAKER.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.BTC_AIRDROP.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.ORDINALS_STAKER.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.ORDINALS_OFFERS.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.LOCKER.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.AUCTION.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.VESTING.value]: ([_onClick, __onClick]) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={_onClick}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
};

function List() {
  const navigate = useNavigate();
  const { drawers } = useDrawers();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.PRODUCT);
  const LIST = t("LIST", { returnObjects: true });
  const [open, setOpen] = useState({ right: false, left: false });
  const [enabled, setEnabled] = useState({
    configure: false,
  });

  const { data, loading, error } = useQuery(graphql.queries.PRODUCTS, {
    variables: { where: {} },
  });

  const handleSettingsClick = (productId) => {
    setEnabled({ configure: true });
    setOpen((prevState) => ({
      ...prevState,
      right: true,
    }));
  };

  useEffect(() => {
    const displayDrawer = () => {
      drawers.setItems((_) =>
        Drawers({
          open,
          enabled,
          onClose: () => {
            drawers.setItems((_) => []);
            setOpen((_) => ({ ..._, right: false, left: false }));
          },
        }).filter((_) => _.enabled)
      );
    };
    displayDrawer();
  }, [open, enabled]);

  if (error || loading) return <Loading />;

  const {
    products: { data: products },
  } = data;

  return (
    <Container>
      <Header key={cuid()} title={LIST.HEADER.TITLE} />
      <div className="products__area">
        {products.map((item) => {
          return (
            <div className="item">
              <div className="head">
                <span>
                  {moment
                    .unix(parseInt(item.key.createdDate / 1000))
                    .format("DD MMM, HH:mm")}
                </span>
                <FiSettings
                  size={20}
                  color={COLORS.C_DARK}
                  onClick={() => handleSettingsClick(item.id)}
                  className="action"
                />
              </div>
              <div className="type">
                <img
                  src={
                    Object.values(PRODUCT_TYPES).find(
                      (__) => __.value === item.type
                    ).icon
                  }
                  alt={item.type}
                />
                <span>
                  {
                    Object.values(PRODUCT_TYPES).find(
                      (__) => __.value === item.type
                    ).label
                  }
                </span>
              </div>
              <div className="name">
                <h4>{item.name}</h4>
                <div className="status">
                  <FaCaretRight size={20} /> <span>{"Active"}</span>
                </div>
              </div>
            </div>
          );
        })}
        <div
          className="add"
          onClick={() =>
            navigate(SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.CREATE.PROCESS.path)
          }
        >
          <FiPlus color={COLORS.C_GRAY} />
          <span>{LIST.HEADER.NEW}</span>
        </div>
      </div>
    </Container>
  );
}

export default List;
