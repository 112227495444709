import { get, isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import RadioGroup from "shared/components/RadioGroup";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";

const Profile = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const { t } = useTranslation(NAME_SPACES.AUTH);
  return (
    <div className="onboarding__area">
      <div className="onboarding__area--content">
        <div className="head">
          <span>{t("SIGNUP.PROFILE.ALREADY_HAVE_ACCOUNT")}</span>
          <Button
            mode={"secondary--light"}
            onClick={() => {
              return navigate(SCREENS.AUTH.SIGN_IN.path);
            }}
          >
            {t("SIGNUP.PROFILE.NAVIGATION.SIGNIN")}
          </Button>
        </div>
        <div className="form">
          <h2 className="title">{t("SIGNUP.PROFILE.TITLE")}</h2>
          <div className="feild--item">
            <label className="label">{t("SIGNUP.PROFILE.SUBTITLE")}</label>
            <div className="m-top-24 m-bottom-24">
              <RadioGroup
                options={[
                  {
                    label: t("SIGNUP.PROFILE.COMPANY"),
                    value: "org-admin",
                  },
                  {
                    label: t("SIGNUP.PROFILE.DEVELOPER"),
                    value: "developer",
                  },
                ]}
                onChange={(event) => {
                  return setState((_) => ({
                    organization: {
                      users: [{ meta: { type: event.target.value } }],
                    },
                  }));
                }}
              />
            </div>
            <Button
              mode={"primary"}
              disabled={isEmpty(get(state, "organization.users"))}
              size={"full--width"}
              alignment={"right"}
              onClick={() => {
                return navigate(SCREENS.AUTH.SIGNUP.ACCESS.path, { state });
              }}
            >
              {t("SIGNUP.PROFILE.NAVIGATION.NEXT")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
