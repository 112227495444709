import { Input } from "antd";
import PropTypes from "prop-types";

import Label from "shared/components/Label";

const Simple = ({ label, placeholder, errors, touched, icon, ...rest }) => {
  return (
    <div className="input__wrapper">
      <Label label={label} icon={icon} />
      <div className={`input__wrapper--field`}>
        <Input.Password
          className={`${touched && errors && "input--borderd--red"} ${
            rest.disabled && "disable--input"
          }`}
          placeholder={placeholder}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Simple.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Simple.defaultProps = {
  label: "",
  placeholder: "",
};

export default Simple;
