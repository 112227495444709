import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.WEBHOOK);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={(values) =>
          onSubmit({ ...values, organization: { id: values.organization } })
        }
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
          <Header
            key={"header"}
            title={CREATE.HEADER.TITLE}
            subtitle={CREATE.HEADER.ACTION}
            actions={[
              {
                text: CREATE.HEADER.DISCARD,
                onClick: cancel,
                variant: "outlined",
              },
              {
                text: CREATE.HEADER.SAVE,
                onClick: handleSubmit,
              },
            ]}
          />,
          <Row gutter={[32]}>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 20, offset: 2, order: 1 }}
              lg={{ span: 14, offset: 5, order: 2 }}
            >
              <div className="inputs--container">
                <div className="inputs--container__header">
                  <span>{FORM.GENERAL_INFO}</span>
                </div>
                <Form type={"vertical"}>
                  <div className="field--item">
                    <label>Name</label>
                    <Input
                      placeholder={FORM.NAME}
                      {...bindInputProps({
                        name: "name",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>

                  <div className="field--item">
                    <label>Description</label>
                    <Input
                      placeholder={FORM.DESCRIPTION}
                      {...bindInputProps({
                        name: "description",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>

                  <div className="field--item">
                    <label>Url</label>
                    <Input
                      placeholder={FORM.URL}
                      {...bindInputProps({
                        name: "url",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>
                  <div className="field--item">
                    <label>Select</label>
                    <Select
                      type="async"
                      node={[
                        graphql.queries.ORGANIZATIONS,
                        {
                          variables: { where: {} },
                        },
                        "organizations.data",
                      ]}
                      placeholder={FORM.ORGANIZATION}
                      {...bindInputProps({
                        name: "organization",
                        values,
                        ...formProps,
                      })}
                    />
                  </div>
                </Form>
              </div>
            </Col>
          </Row>,
        ]}
      </Formik>
    </Container>
  );
};

export default Create;
