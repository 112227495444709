import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Form from "shared/components/Form";
import Input from "shared/components/Form/Input";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onSubmit, feed, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.FEED);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { type } = feed;
  return (
    <Formik
      initialValues={{ type }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              variant: "outlined",
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
            },
          ]}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={12}>
                <Input
                  placeholder={FORM.NAME}
                  {...bindInputProps({ name: "type", ...formProps })}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
      ]}
    </Formik>
  );
};

export default View;
