import { useQuery, useMutation } from "@apollo/client";
import { Col, Modal, Table } from "antd";
import React, { useState } from "react";
import Container from "shared/components/Container";
import graphql from "utils/api/graphql";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import { COLORS } from "shared/style/colors";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import { BsArrowLeft } from "react-icons/bs";
import { get } from "lodash";

function List() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.WORKER);
  const LIST = t("LIST", { returnObjects: true });
  const DETAIL = t("DETAIL", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.WORKERS, {
    variables: { where: {} },
  });
  const [updateWorker] = useMutation(graphql.mutations.UPDATE_WORKER, {
    onCompleted: () => {},
    onError: () => {},
  });
  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: LIST.TYPE,
      dataIndex: "type",
      align: "left",
    },
    {
      title: LIST.CONFIGURATION,
      dataIndex: "configuration",
      align: "left",
      render: (_) => (
        <pre>
          <code>{JSON.stringify(_)}</code>
        </pre>
      ),
    },
  ];

  if (error || loading) return <Loading />;

  const {
    workers: { data: workers },
  } = data;

  return (
    <Container>
      <Table
        className="custom__table m-top-20"
        scroll={{ x: 400 }}
        columns={columns}
        dataSource={workers}
        rowKey={"id"}
        size="small"
        onRow={(record) => {
          return {
            onClick: () => {
              setOpen(true);
              setSelected(record);
            },
          };
        }}
      />
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <div className="form">
          <div className="back__arrow" onClick={() => {}}>
            <BsArrowLeft size={24} color={COLORS.C_BLACK} />
            <span>{DETAIL.NAVIGATION.BACK}</span>
          </div>
          <h2 className="title">{`${t("DETAIL.HEADER.TITLE")} ${get(
            selected,
            "name"
          )}`}</h2>
          <div className="feild--item">
            <div className="m-top-24 m-bottom-24 ">
              <Col span={24}>
                <Input
                  placeholder={DETAIL.FORM.POWER}
                  onChange={(__) => {
                    return setConfiguration((_) => ({
                      ..._,
                      power: __.target.value,
                    }));
                  }}
                />
              </Col>
            </div>
            <Button
              mode={"primary"}
              size={"full--width"}
              alignment={"right"}
              onClick={() => {
                updateWorker({
                  variables: {
                    where: { id: selected.id },
                    data: { configuration },
                  },
                });
              }}
            >
              {DETAIL.FORM.SUBMIT}
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default List;
