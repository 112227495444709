import PropTypes from "prop-types";

const Heading = ({ title }) => {
  return (
    <div className="heading__area">
      <h2>{title}</h2>
    </div>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
};

Heading.defaultProps = {};

export default Heading;
