import { gql } from "@apollo/client";

export const BALANCE = gql`
  query Balance($where: AccountWhereInput) {
    balance(where: $where) {
      debit
      credit
      id
      wallet
    }
  }
`;

const account = { BALANCE };

export default account;
