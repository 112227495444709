import { gql } from "@apollo/client";

export const ESCROWS = gql`
  query Escrows($where: EscrowWhereInput, $skip: Int, $take: Int) {
    escrows(where: $where, skip: $skip, take: $take) {
      data {
        id
        meta
        endDate
        status
        transactions {
          id
          hex
          base64
          type
          txid
        }
        collateral {
          assets {
            usdValue
            id
            type
            content {
              meta
              node
            }
            action {
              id
              type
              configuration
            }
          }
        }
        flowEscrow {
          flow {
            id
          }
        }
      }
      count
    }
  }
`;

export const ESCROW = gql`
  query Escrow($where: EscrowWhereInput) {
    escrow(where: $where) {
      id
      meta
      status
      transactions {
        id
        hex
        base64
        type
        txid
      }
      collateral {
        assets {
          usdValue
          id
          type
          content {
            meta
            node
          }
          action {
            id
            type
            configuration
          }
        }
      }
    }
  }
`;

const escrow = { ESCROWS, ESCROW };

export default escrow;
