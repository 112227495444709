import { SCREENS } from "utils/constants";
import Detail from "./Detail";

const routes = [
  {
    path: `${SCREENS.PRIVATE.ORGANIZATION.PROFILE.DETAIL}/:id`,
    exact: true,
    element: <Detail />,
  },
];

export default routes;
