import gql from "graphql-tag";

export const CLAIM = gql`
  mutation Claim($data: DiscountWhereInput) {
    claim(data: $data) {
      id
    }
  }
`;

const discounts = { CLAIM };

export default discounts;
