export const NAME_SPACES = {
  PRIVATE: {
    ADMIN: {
      ORGANIZATION: "organization",
      WEBHOOK: "webhook",
      FEED: "feed",
      USER: "user",
    },
    ORGANIZATION: {
      HOME: "home",
      WEBHOOK: "webhook",
      USER: "user",
      RESERVES: "reserves",
      ORDINAL: "ordinal",
      PROFILE: "profile",
      PRODUCT: "product",
      LOAN: "loan",
      LIMIT: "limit",
      VESTING: "vesting",
      WORKER: "worker",
    },
  },
  PUBLIC: {
    RESERVES: "public.reserves",
  },
  LAYOUT: {
    HEADER: "layout.header",
  },
  PROFILE: "profile",
  AUTH: "auth",
  WELCOME: "welcome",
  NOTIFICATION: "notification",
};
