import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./en";
import { frTranslation } from "./fr";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    fr: frTranslation,
    en: enTranslation,
  },
  lng: "en",
  ns: ["global"],
  defaultNS: "global",
  debug: false,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
