import { BiMessageRoundedError } from "react-icons/bi";
import { COLORS } from "shared/style/colors";
import { useWindowSize } from "shared/hooks/resize";

const Error = ({ title, message }) => {
  const [height] = useWindowSize();
  return (
    <div className={"error__area"} style={{ height: `calc(${height}px / 2)` }}>
      <BiMessageRoundedError size={64} color={COLORS.C_RED} />
      <div className="content">
        <span>{title}</span>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Error;
