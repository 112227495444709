import gql from "graphql-tag";

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($data: ProductDataInput) {
    createProduct(data: $data) {
      id
    }
  }
`;

const products = { CREATE_PRODUCT };

export default products;
