import { gql } from "@apollo/client";

export const PRODUCTS = gql`
  query Products($where: ProductWhereInput) {
    products(where: $where) {
      data {
        id
        name
        type
        key {
          id
          createdDate
        }
      }
      count
    }
  }
`;

export const PRODUCT = gql`
  query Product($where: ProductWhereInput) {
    product(where: $where) {
      id
      name
      type
      key {
        id
        createdDate
      }
    }
  }
`;

const product = { PRODUCTS, PRODUCT };

export default product;
