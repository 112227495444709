import React, { useContext, useEffect } from "react";
import { findKey } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS, USER_TYPES } from "utils/constants";
import { StoreContext } from "shared/contexts/store";
import Button from "shared/components/Button";

const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.WELCOME);
  const { user, accessToken } = useContext(StoreContext);

  useEffect(() => {
    if (accessToken)
      navigate({
        pathname:
          USER_TYPES[findKey(USER_TYPES, (o) => o.type === user.meta.type)]
            .index,
      });
  }, [accessToken, user, navigate]);

  return (
    <div className="onboarding__area">
      <div className="onboarding__area--content">
        <div className="head">
          <Button
            mode={"secondary--light"}
            onClick={() => {
              return navigate(SCREENS.AUTH.SIGN_IN.path);
            }}
          >
            {t("HEADER.ACTIONS.SIGNIN")}
          </Button>
          <Button
            mode={"secondary--light"}
            onClick={() => {
              return navigate(SCREENS.AUTH.SIGNUP.PROFILE.path);
            }}
          >
            {t("HEADER.ACTIONS.SIGNUP")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
