import { gql } from "@apollo/client";

export const POSITIONS = gql`
  query Positions($where: PositionWhereInput, $orderBy: [JSONObject]) {
    positions(where: $where, orderBy: $orderBy) {
      data {
        id
        user
        type
        size
        support
        market
        status
        positionId
        entryPrice
      }
      count
    }
  }
`;

const positions = { POSITIONS };

export default positions;
