import PropTypes from "prop-types";
import Async from "./Async";
import Simple from "./Simple";
import Sync from "./Sync";

const TYPES = {
  sync: Sync,
  async: Async,
  simple: Simple
};
const Select = ({ touched, ...props }) => {
  const { type } = props;
  const Widget = TYPES[type];
  return <Widget {...props} />;
};

Select.propTypes = {
  type: PropTypes.string.isRequired,
};

Select.defaultProps = {
  type: "simple",
};

export default Select;
