import { Col, Row } from "antd";
import PropTypes from "prop-types";

const Component = ({ title, subtitle, actions, children }) => {
  return (
    <div className="form-highlight--wrapper">
      <Row>
        <Col lg={24} xs={24}>
          <div className="form-highlight--head">
            <div className="form-highlight--title">
              <h3>{title}</h3>
              <span>{subtitle}</span>
            </div>
            <div className="form-highlight--actions">{actions}</div>
          </div>
        </Col>
        <Col lg={24} xs={24}>
          <div className="form-highlight--content">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node,
};

export default Component;
