import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { StoreContext } from "shared/contexts/store";
import { SCREENS } from "utils/constants";
import { isNil } from "lodash";

const PrivateRoute = ({ component, path }) => {
  const { accessToken } = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNil(accessToken) && path === location.pathname)
      return navigate(SCREENS.PUBLIC.INDEX);
  }, [accessToken, location.pathname, navigate, path]);

  if (isNil(accessToken) && path === location.pathname) return () => null;

  return component;
};
export default PrivateRoute;
