import { Input } from "antd";
import PropTypes from "prop-types";

const Simple = ({ label, placeholder, ...rest }) => {
  return (
    <div className="input--transparent">
      <Input placeholder={placeholder} {...rest} />
      {label && <span>{label}</span>}
    </div>
  );
};

Simple.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Simple.defaultProps = {
  label: "",
  placeholder: "",
};

export default Simple;
