import NumberInput from "shared/components/Input/Number";

const Large = ({ elements = [] }) => {
  const [input_1, input_2] = elements;

  return (
    <div className="position--input--values">
      <div className="item">
        <NumberInput
          value={input_1.value}
          placeholder={"0.0"}
          label={input_1.label}
          disabled={input_1?.disabled}
          onChange={(e) => input_1.onChange(e.target.value)}
        />
      </div>
      <div className="divider" />
      <div className="item">
        <NumberInput
          value={input_2.value}
          placeholder={"0.0"}
          label={input_2.label}
          disabled={input_2?.disabled}
          onChange={(e) => input_2.onChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Large;
