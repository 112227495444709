import { SCREENS } from "utils/constants";
import Logout from "./Logout";
import Signin from "./Signin";
import Signup from "./Signup";

const routes = [
  {
    path: SCREENS.AUTH.LOGOUT.path,
    exact: true,
    element: <Logout />,
  },
  {
    path: SCREENS.AUTH.SIGN_IN.path,
    exact: true,
    element: <Signin />,
  },
  ...Signup,
];

export default routes;
