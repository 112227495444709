const tickers = [
  {
    ticker: "ordi",
    holdersCount: 12645,
    historyCount: 203203,
    inscriptionNumber: 348020,
    inscriptionId:
      "b61b0172d95e266c18aea0c624db987e971a5d6d4ebc2aaed85da4642d635735i0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21493,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779832,
    deployBlocktime: 1678248991,
    completeHeight: 780070,
    completeBlocktime: 1678404195,
    inscriptionNumberStart: 348020,
    inscriptionNumberEnd: 384419,
  },
  {
    ticker: "meme",
    holdersCount: 5056,
    historyCount: 125344,
    inscriptionNumber: 352242,
    inscriptionId:
      "307ffac5d20fc188f723706f85d75c926550d536f5fd1113839586f38542971ci0",
    max: "99999",
    limit: "1",
    minted: "99999",
    totalMinted: "99999",
    confirmedMinted: "99999",
    confirmedMinted1h: "99999",
    confirmedMinted24h: "99999",
    mintTimes: 99999,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779887,
    deployBlocktime: 1678279462,
    completeHeight: 784004,
    completeBlocktime: 1680673121,
    inscriptionNumberStart: 352242,
    inscriptionNumberEnd: 901815,
  },
  {
    ticker: "punk",
    holdersCount: 2314,
    historyCount: 16125,
    inscriptionNumber: 356204,
    inscriptionId:
      "2782fcc4173baddd337b6adaaca11535daa821ab6c234d8eee22257260ea7d07i0",
    max: "10000",
    limit: "1",
    minted: "10000",
    totalMinted: "10000",
    confirmedMinted: "10000",
    confirmedMinted1h: "10000",
    confirmedMinted24h: "10000",
    mintTimes: 10000,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779964,
    deployBlocktime: 1678333933,
    completeHeight: 780694,
    completeBlocktime: 1678751156,
    inscriptionNumberStart: 356204,
    inscriptionNumberEnd: 457456,
  },
  {
    ticker: "pepe",
    holdersCount: 4542,
    historyCount: 78019,
    inscriptionNumber: 356777,
    inscriptionId:
      "54d5fe82f5d284363fec6ae6137d0e5263e237caf15211078252c0d95af8943ai0",
    max: "42069000",
    limit: "1000",
    minted: "42069000",
    totalMinted: "42069000",
    confirmedMinted: "42069000",
    confirmedMinted1h: "42069000",
    confirmedMinted24h: "42069000",
    mintTimes: 42769,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779969,
    deployBlocktime: 1678338023,
    completeHeight: 781773,
    completeBlocktime: 1679381241,
    inscriptionNumberStart: 356777,
    inscriptionNumberEnd: 564492,
  },
  {
    ticker: "BRUH",
    holdersCount: 299,
    historyCount: 11474,
    inscriptionNumber: 356831,
    inscriptionId:
      "7845e5c3c81fa10ab153db26bd6a5928e86ccf326cf7e7d931f430282d4d0970i0",
    max: "1000000000",
    limit: "100000",
    minted: "1000000000",
    totalMinted: "1000000000",
    confirmedMinted: "1000000000",
    confirmedMinted1h: "1000000000",
    confirmedMinted24h: "1000000000",
    mintTimes: 10089,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779970,
    deployBlocktime: 1678339002,
    completeHeight: 784597,
    completeBlocktime: 1681018290,
    inscriptionNumberStart: 356831,
    inscriptionNumberEnd: 1028418,
  },
  {
    ticker: "gold",
    holdersCount: 704,
    historyCount: 26548,
    inscriptionNumber: 357023,
    inscriptionId:
      "afd28d245def0a2e6be86852e8aa327214d0c94a04f5539b268e43e67d1a33f1i0",
    max: "24000",
    limit: "1",
    minted: "24000",
    totalMinted: "24000",
    confirmedMinted: "24000",
    confirmedMinted1h: "24000",
    confirmedMinted24h: "24000",
    mintTimes: 24000,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779970,
    deployBlocktime: 1678339002,
    completeHeight: 784604,
    completeBlocktime: 1681021547,
    inscriptionNumberStart: 357023,
    inscriptionNumberEnd: 1032850,
  },
  {
    ticker: "BAYC",
    holdersCount: 956,
    historyCount: 12774,
    inscriptionNumber: 357053,
    inscriptionId:
      "bd0d31746fd7551adedcbdc97b03f6927a1798bf8d9dad9fec0b6b140ef43d99i0",
    max: "10000",
    limit: "1",
    minted: "10000",
    totalMinted: "10000",
    confirmedMinted: "10000",
    confirmedMinted1h: "10000",
    confirmedMinted24h: "10000",
    mintTimes: 10000,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779971,
    deployBlocktime: 1678339934,
    completeHeight: 783565,
    completeBlocktime: 1680411992,
    inscriptionNumberStart: 357053,
    inscriptionNumberEnd: 700350,
  },
  {
    ticker: "<10K",
    holdersCount: 492,
    historyCount: 11012,
    inscriptionNumber: 357087,
    inscriptionId:
      "d1f83be6de1a87a1754c6a1ff3e8ccd6c30cf671a16de74e23a6df10d06e5ffci0",
    max: "9999",
    limit: "1",
    minted: "9999",
    totalMinted: "9999",
    confirmedMinted: "9999",
    confirmedMinted1h: "9999",
    confirmedMinted24h: "9999",
    mintTimes: 9999,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779971,
    deployBlocktime: 1678339934,
    completeHeight: 784575,
    completeBlocktime: 1681005795,
    inscriptionNumberStart: 357087,
    inscriptionNumberEnd: 1016165,
  },
  {
    ticker: "sats",
    holdersCount: 41720,
    historyCount: 21506047,
    inscriptionNumber: 357097,
    inscriptionId:
      "9b664bdd6f5ed80d8d88957b63364c41f3ad4efb8eee11366aa16435974d9333i0",
    max: "2100000000000000",
    limit: "100000000",
    minted: "2100000000000000",
    totalMinted: "2100000000000000",
    confirmedMinted: "2100000000000000",
    confirmedMinted1h: "2100000000000000",
    confirmedMinted24h: "2100000000000000",
    mintTimes: 21010783,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779971,
    deployBlocktime: 1678339934,
    completeHeight: 809135,
    completeBlocktime: 1695552732,
    inscriptionNumberStart: 357097,
    inscriptionNumberEnd: 34539796,
  },
  {
    ticker: "sato",
    holdersCount: 291,
    historyCount: 10737,
    inscriptionNumber: 357274,
    inscriptionId:
      "89b7cb6aa57d439b9541b169a95096db30c726b7beee9ef8af34edc942795e6ei0",
    max: "13370000",
    limit: "1337",
    minted: "13370000",
    totalMinted: "13370000",
    confirmedMinted: "13370000",
    confirmedMinted1h: "13370000",
    confirmedMinted24h: "13370000",
    mintTimes: 10032,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779972,
    deployBlocktime: 1678340348,
    completeHeight: 785193,
    completeBlocktime: 1681374803,
    inscriptionNumberStart: 357274,
    inscriptionNumberEnd: 1098567,
  },
  {
    ticker: "fake",
    holdersCount: 289,
    historyCount: 21316,
    inscriptionNumber: 358611,
    inscriptionId:
      "d1c47ad2edf07857e24ca96b38c65e95c58ef13a37d0918a26069c4f0fb1b7afi0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21024,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779976,
    deployBlocktime: 1678340817,
    completeHeight: 786745,
    completeBlocktime: 1682294267,
    inscriptionNumberStart: 358611,
    inscriptionNumberEnd: 1535127,
  },
  {
    ticker: "rare",
    holdersCount: 483,
    historyCount: 21852,
    inscriptionNumber: 358612,
    inscriptionId:
      "540325361cf8bea427f72e30f2edee31373d6398cf8473ee332f0cbacf504b20i0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21037,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779976,
    deployBlocktime: 1678340817,
    completeHeight: 786598,
    completeBlocktime: 1682205897,
    inscriptionNumberStart: 358612,
    inscriptionNumberEnd: 1347291,
  },
  {
    ticker: "bits",
    holdersCount: 288,
    historyCount: 23561,
    inscriptionNumber: 359085,
    inscriptionId:
      "4911fa027c8f0e0259b877dc0069499da9b394910643dcee5e49f1fd8ebe333bi0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21121,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779978,
    deployBlocktime: 1678345834,
    completeHeight: 786613,
    completeBlocktime: 1682215342,
    inscriptionNumberStart: 359085,
    inscriptionNumberEnd: 1358522,
  },
  {
    ticker: "doge",
    holdersCount: 5416,
    historyCount: 92254,
    inscriptionNumber: 359088,
    inscriptionId:
      "c7ee58a761f23d68b4a35c16f25e96fe9317a63d8a951eff9773099ba08cb6adi0",
    max: "1000000000000",
    limit: "4200",
    minted: "363524894",
    totalMinted: "363524894",
    confirmedMinted: "359933893",
    confirmedMinted1h: "359933893",
    confirmedMinted24h: "359484493",
    mintTimes: 88158,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779978,
    deployBlocktime: 1678345834,
    completeHeight: 0,
    completeBlocktime: 0,
    inscriptionNumberStart: 359088,
    inscriptionNumberEnd: 43419245,
  },
  {
    ticker: "HAMS",
    holdersCount: 675,
    historyCount: 2570,
    inscriptionNumber: 359098,
    inscriptionId:
      "68c2a47b6c687039da5178c89af0038907d97691dcc7cfdbdb93592444671721i0",
    max: "1000000000000",
    limit: "4200",
    minted: "9665895",
    totalMinted: "9665895",
    confirmedMinted: "9623895",
    confirmedMinted1h: "9623895",
    confirmedMinted24h: "9615495",
    mintTimes: 2373,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779979,
    deployBlocktime: 1678345973,
    completeHeight: 0,
    completeBlocktime: 0,
    inscriptionNumberStart: 359098,
    inscriptionNumberEnd: 43416559,
  },
  {
    ticker: "dick",
    holdersCount: 267,
    historyCount: 7304,
    inscriptionNumber: 359149,
    inscriptionId:
      "2059ef7438edec1a8da0845e2aedce946aeff9d4255b3b2df8043115030afb75i0",
    max: "69696969",
    limit: "10000",
    minted: "69696969",
    totalMinted: "69696969",
    confirmedMinted: "69696969",
    confirmedMinted1h: "69696969",
    confirmedMinted24h: "69696969",
    mintTimes: 7066,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779981,
    deployBlocktime: 1678346988,
    completeHeight: 786490,
    completeBlocktime: 1682143102,
    inscriptionNumberStart: 359149,
    inscriptionNumberEnd: 1287442,
  },
  {
    ticker: "coin",
    holdersCount: 671,
    historyCount: 21767,
    inscriptionNumber: 359230,
    inscriptionId:
      "e98d974e0c6ef7efdd32553c972a7314084db2c397a5ac68dabe24b73f424769i0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21027,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779981,
    deployBlocktime: 1678346988,
    completeHeight: 785982,
    completeBlocktime: 1681832022,
    inscriptionNumberStart: 359230,
    inscriptionNumberEnd: 1169564,
  },
  {
    ticker: "wang",
    holdersCount: 144,
    historyCount: 2366,
    inscriptionNumber: 359264,
    inscriptionId:
      "5adad017da810fa9de25c24c19614ad3c79d607689236c9e23b5856951d179cai0",
    max: "21000000",
    limit: "10000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 2103,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779982,
    deployBlocktime: 1678347151,
    completeHeight: 785213,
    completeBlocktime: 1681387237,
    inscriptionNumberStart: 359264,
    inscriptionNumberEnd: 1100036,
  },
  {
    ticker: "usdt",
    holdersCount: 258,
    historyCount: 1258,
    inscriptionNumber: 359557,
    inscriptionId:
      "794b1e76bedf617878b17ac11d482db7c7fbd07f9eaa9d712f774486e089352bi0",
    max: "1000000000000",
    limit: "1",
    minted: "1209",
    totalMinted: "1209",
    confirmedMinted: "1209",
    confirmedMinted1h: "1209",
    confirmedMinted24h: "1209",
    mintTimes: 1209,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779984,
    deployBlocktime: 1678348707,
    completeHeight: 0,
    completeBlocktime: 0,
    inscriptionNumberStart: 359557,
    inscriptionNumberEnd: 42690814,
  },
  {
    ticker: "game",
    holdersCount: 448,
    historyCount: 22195,
    inscriptionNumber: 359567,
    inscriptionId:
      "2aa6debdb76671bd8da0ab0418aef321312f6fa4003d761525a714e7d4526d08i0",
    max: "21000000",
    limit: "1000",
    minted: "21000000",
    totalMinted: "21000000",
    confirmedMinted: "21000000",
    confirmedMinted1h: "21000000",
    confirmedMinted24h: "21000000",
    mintTimes: 21025,
    decimal: 18,
    creator: "",
    txid: "",
    deployHeight: 779984,
    deployBlocktime: 1678348707,
    completeHeight: 786447,
    completeBlocktime: 1682118801,
    inscriptionNumberStart: 359567,
    inscriptionNumberEnd: 1244410,
  },
];

export default tickers;
