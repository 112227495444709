import { gql } from "@apollo/client";

export const LIMITS = gql`
  query Limits($where: LimitWhereInput, $skip: Int, $take: Int) {
    limits(where: $where, skip: $skip, take: $take) {
      data {
        id
        type
        limitItems {
          value
        }
      }
      count
    }
  }
`;

export const LIMIT = gql`
  query Limit($where: LimitWhereInput) {
    limit(where: $where) {
      id
      type
      limitItems {
        value
      }
    }
  }
`;

const limit = { LIMITS, LIMIT };

export default limit;
