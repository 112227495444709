import { useState } from "react";
import id from "./id";

export const useMutation = (promise, options = {}) => {
  const { onError = () => {}, onSuccess = () => {} } = options;
  const [state, setState] = useState({});

  return [
    async (payload) => {
      setState((state) => ({ ...state, loading: true, called: true }));
      try {
        const { data } = await promise(payload);
        onSuccess({ data });
        setState((state) => ({ ...state, loading: false, data }));
      } catch (error) {
        onError({ error });
        setState((state) => ({ ...state, loading: false, error }));
      }
    },
    state,
  ];
};

export const api = { id };

const client = { useMutation, api: { id } };
export default client;
