import { gql } from "@apollo/client";

const POSITION_ADDED = gql`
  subscription positionAdded($where: PositionWhereInput) {
    positionAdded(where: $where) {
      id
      user
      type
      size
      support
      market
      status
      positionId
      entryPrice
    }
  }
`;

const POSITION_UPDATED = gql`
  subscription positionUpdated($where: PositionWhereInput) {
    positionUpdated(where: $where) {
      id
      user
      type
      size
      support
      market
      status
      positionId
      entryPrice
    }
  }
`;

const positions = { POSITION_ADDED, POSITION_UPDATED };

export default positions;
