import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import cuid from "cuid";
import moment from "moment";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import Button from "shared/components/Button";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { ACTIONS_TYPES, ADAPATER_ASSETS, DATE_FORMAT } from "utils/constants";

const formatter = Intl.NumberFormat("en", { notation: "compact" });
const USD_TO_BTC = 29052.4;

const List = () => {
  const { search } = useLocation();
  const { id } = qs.parse(search, { ignoreQueryPrefix: true });
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.LOAN);
  const BTC = t("BTC", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.ESCROWS, {
    variables: { where: { product: { id } } },
  });
  const columns = [
    {
      title: BTC.LIST.ID,
      dataIndex: "id",
      render: (item) => item,
    },
    {
      title: BTC.LIST.COLLATERAL,
      dataIndex: "collateral",
      align: "right",
      render: (item) => {
        return item.assets
          .filter((asset) => asset.action.type === ACTIONS_TYPES.LOCK)
          .map((asset) => (
            <a
              key={asset.id}
              href={`${
                process.env.REACT_APP_EXPLORER_URL
              }/${process.env.REACT_APP_NETWORK.toLocaleLowerCase()}/tx/eb4b461cd5f5cd103c9b0789d149437eca0c1154ce5d14098e77f09cb1465b95`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tag className="btc--tag">
                {asset.content.meta.amount} {ADAPATER_ASSETS.SATOSHI}
              </Tag>
            </a>
          ));
      },
    },
    {
      title: BTC.LIST.TREND,
      render: (_, __) => {
        const variation = __.collateral.assets
          .filter((asset) => asset.action.type === ACTIONS_TYPES.LOCK)
          .reduce((accumulator, element) => {
            const diff = 1 - USD_TO_BTC / element.usdValue;
            accumulator = accumulator + diff;
            return accumulator;
          }, 0);
        const icon =
          variation < 0 ? (
            <AiOutlineArrowDown color={"red"} fontSize={12} />
          ) : (
            <AiOutlineArrowUp color={"green"} fontSize={12} />
          );
        return (
          <span>
            {formatter.format(variation)}
            {icon}
          </span>
        );
      },
    },
    {
      title: BTC.LIST.EXPIRES,
      dataIndex: "endDate",
      align: "right",
      render: (_, __) => {
        return moment(__).format(DATE_FORMAT);
      },
    },
    {
      title: BTC.LIST.STATE,
      dataIndex: "state",
      align: "right",
      render: (_) => {
        return (
          <Tag>{moment().diff(moment()) > 0 ? "Active" : "Liquidatable"}</Tag>
        );
      },
    },
    {
      title: BTC.LIST.ACTIONS.TITLE,
      key: "action",
      align: "right",
      render: (_) => (
        <div className="d-flex justify--end">
          <Button mode={"primary"} onClick={() => {}}>
            {BTC.LIST.ACTIONS.ITEMS.APPROVE}
          </Button>
          <Button mode={"secondary"} onClick={() => {}}>
            {BTC.LIST.ACTIONS.ITEMS.DECLINE}
          </Button>
          <Button mode={"secondary"} onClick={() => {}}>
            {BTC.LIST.ACTIONS.ITEMS.LIQUIDATE}
          </Button>
        </div>
      ),
    },
  ];

  if (loading || error) return <Loading />;

  const { escrows } = data;

  return (
    <Table
      className="custom__table m-top-24"
      size="small"
      dataSource={escrows.data}
      columns={columns}
      rowKey={"id"}
    />
  );
};

function View() {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.LOAN);
  const BTC = t("BTC", { returnObjects: true });

  return (
    <div className="organization__area">
      <Container>
        <Header
          key={cuid()}
          title={BTC.LIST.HEADER.TITLE}
          subtitle={BTC.LIST.HEADER.SUBTITLE}
          actions={[]}
        />
        <List />
      </Container>
    </div>
  );
}

export default View;
