import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST);
  const [createOrganization] = useMutation(
    graphql.mutations.CREATE_ORGANIZATION,
    {
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST);
      },
      onError: () => {},
    }
  );
  const onSubmit = (values) => {
    return createOrganization({
      variables: {
        data: {
          ...values,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
