import { DATE_TIME_FORMAT, SCREENS } from "utils/constants";
import { Table, Dropdown } from "antd";
import cuid from "cuid";
import moment from "moment";
import Container from "shared/components/Container";
import Input from "shared/components/Input";
import { useNavigate } from "react-router-dom";
import graphql from "utils/api/graphql";
import Header from "shared/components/Header";
import { useQuery } from "@apollo/client";
import SatoshiBWM from "shared/assets/satoshi-bwn.png";
import { FaCaretRight, FaRegEdit } from "react-icons/fa";
import { BsThreeDotsVertical, BsTrash3, BsSearch } from "react-icons/bs";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import Error from "shared/components/Error";

function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.HOME);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.ORGANIZATIONS, {
    variables: { where: {} },
  });

  const items = [
    {
      label: (
        <span
          className="min--row"
          onClick={() => {
            navigate(SCREENS.PRIVATE.ORGANIZATION.TRAILS.LIST.path);
          }}
        >
          <BsSearch /> <label>{LIST.ACTIONS.LOGS}</label>
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span className="min--row">
          <BsTrash3 /> <label>{LIST.ACTIONS.EDIT}</label>
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span className="min--row">
          <FaRegEdit /> <label>{LIST.ACTIONS.DELETE}</label>
        </span>
      ),
      key: "2",
    },
  ];
  const columns = [
    {
      width: 80,
      title: LIST.LOGO,
      key: "logo",
      dataIndex: "logo",
      render: (logo) => {
        return logo ? (
          <img src={logo} alt="" className="logo--medium" />
        ) : (
          <img src={SatoshiBWM} alt="logo" className="logo--medium" />
        );
      },
    },
    {
      title: LIST.NAME,
      key: "name",
      dataIndex: "name",
      width: "40%",
    },
    {
      title: LIST.STATUS,
      key: "active",
      dataIndex: "active",
      render: () => {
        return (
          <span className="status__area success">
            <FaCaretRight />
            <span>Active</span>
          </span>
        );
      },
    },
    {
      title: LIST.CREATED_AT,
      key: "createdDate",
      dataIndex: "createdDate",
      render: (createdDate) => (
        <span className="date">
          {moment.unix(createdDate / 1000).format(DATE_TIME_FORMAT)}
        </span>
      ),
    },
    {
      title: LIST.ACTIONS.TITLE,
      key: "actions",
      align: "right",
      width: 80,
      fixed: "right",
      render: () => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <BsThreeDotsVertical />
        </Dropdown>
      ),
    },
  ];

  if (loading) return <Loading />;

  if (error)
    return <Error title={t("ERROR.TITLE")} message={t("ERROR.MESSAGE")} />;

  const { organizations } = data;

  return (
    <Container>
      <Header
        key={cuid()}
        title={LIST.HEADER.TITLE}
        actions={[
          {
            text: LIST.HEADER.NEW,
            onClick: () => navigate(),
          },
        ]}
      />
      <div className="m-bottom-20">
        <Input type="search" placeholder={LIST.HEADER.SEARCH} />
      </div>
      <Table
        className="custom__table"
        size="small"
        dataSource={organizations.data}
        columns={columns}
        rowKey={"id"}
      />
    </Container>
  );
}

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.HOME,
    exact: true,
    element: <Home />,
  },
];

export default routes;
