import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ menu, actions, drawers, children }) => {
  const [isOpen, setIsopen] = useState();

  const toggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <main className="layout">
      <Sidebar isOpen={isOpen} onClick={toggleSidebar} menu={menu} />
      <Header showSider={() => setIsopen(true)} actions={actions} menu={menu} />
      <div className="children--layout">
        <div>{children}</div>
      </div>
      {drawers.map(({ Widget, id }) => {
        return <Widget key={id} />;
      })}
    </main>
  );
};

export default Layout;
