import { PRODUCT_TYPES } from "utils/constants";
import Collateraization from "./Collateralization";
const configurations = {
  [PRODUCT_TYPES.BTC_LENDER]: Collateraization,
};
const Configure = () => {
  const Widget = configurations[[PRODUCT_TYPES.BTC_LENDER]];
  return <Widget />;
};

export default Configure;
