import { Row } from "antd";
import PropTypes from "prop-types";

const Component = ({ title, children, ref }) => (
  <div className={"form-container"} ref={ref}>
    <p className={"title"}>{title}</p>
    <Row gutter={[16, 16]}>{children}</Row>
  </div>
);

Component.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Component.defaultProps = {
  title: "",
  children: <></>,
};
export default Component;
