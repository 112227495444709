import { Spin } from "antd";
import { useQuery } from "@apollo/client";
import { Formik } from "formik";
import qs from "qs";
import { findKey, get } from "lodash";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import graphql from "utils/api/graphql";
import { USER_TYPES } from "utils/constants";
import { StoreContext } from "shared/contexts/store";
import { useMutation, api } from "utils/api/rest";
import { bindInputProps } from "utils/helpers/input";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";

const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(NAME_SPACES.AUTH);

  const { setUser, setAccessToken } = useContext(StoreContext);
  const [signIn] = useMutation(api.id.auth.singIn, {
    onSuccess: ({ data: { accessToken, user } }) => {
      setAccessToken({ accessToken });
      setUser({ user });
      return navigate({
        pathname:
          USER_TYPES[findKey(USER_TYPES, (o) => o.type === user.meta.type)]
            .index,
      });
    },
    onError: () => {},
  });
  const { access_token } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { data, error } = useQuery(graphql.queries.PROFILE);

  useEffect(() => {
    const user = get(data, "profile");
    if (user) {
      setUser({ user });
      navigate({
        pathname:
          USER_TYPES[findKey(USER_TYPES, (o) => o.type === user.meta.type)]
            .index,
      });
    }
  }, [setUser, navigate, data]);

  useEffect(() => {}, [setAccessToken, access_token]);

  if (error)
    return (
      <div className="onboarding__area">
        <div className="onboarding__area--content">
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={(data) => signIn({ user: data, source: { id: 2 } })}
          >
            {({ handleSubmit, handleChange, values, ...formProps }) => {
              return (
                <form onSubmit={handleSubmit} className="form">
                  <h2 className="title">{t("SIGNING.FORM.TITLE")}</h2>
                  <div className="field--item">
                    <Input
                      placeholder={t("SIGNING.FORM.USERNAME")}
                      {...bindInputProps({
                        name: "username",
                        values,
                        handleChange,
                        ...formProps,
                      })}
                    />
                    <Input
                      type="password"
                      placeholder={t("SIGNING.FORM.PASSWORD")}
                      {...bindInputProps({
                        name: "password",
                        values,
                        handleChange,
                        ...formProps,
                      })}
                    />
                    <br />
                    <Button
                      htmlType="submit"
                      mode={"primary"}
                      size={"full--width"}
                      alignment={"right"}
                    >
                      {t("SIGNING.FORM.NAVIGATION.SUBMIT")}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );

  return (
    <div>
      <Spin />
    </div>
  );
};

export default Signin;
