import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import cuid from "cuid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BsCalendar4Week } from "react-icons/bs";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { ADAPATER_ASSETS } from "utils/constants";

const formatter = Intl.NumberFormat("en", { notation: "compact" });
const USD_TO_BTC = 29052.4;

const TVL = ({ filter }) => {
  const { data, loading, error } = useQuery(graphql.queries.TVL, {
    variables: {
      lt: { escrow: { endDate: moment().add(filter[0], filter[1]).format() } },
    },
  });

  if (error || loading) return <Loading />;

  return (
    <div className="row">
      <span className="left">
        <BsCalendar4Week color={COLORS.C_WHITE} size={32} />
        <span>{`${filter[2]}`}</span>
      </span>
      <span className="right">
        {`${parseInt(data.tvl.amount)}`}
        <sub>{`${ADAPATER_ASSETS.SATOSHI}`}</sub>
      </span>
    </div>
  );
};

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.RESERVES);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.ESCROWS, {
    variables: { where: {} },
  });

  const columns = [
    {
      title: LIST.ID,
      dataIndex: "id",
      render: (item) => item,
    },
    {
      title: LIST.COLLATERAL,
      dataIndex: "collateral",
      align: "right",
      render: (item) => {
        return item.assets.map((asset) => (
          <a
            key={asset.id}
            href={`${process.env.REACT_APP_EXPLORER_URL
              }/${process.env.REACT_APP_NETWORK.toLocaleLowerCase()}/tx/eb4b461cd5f5cd103c9b0789d149437eca0c1154ce5d14098e77f09cb1465b95`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tag className="btc--tag">
              {asset.amount} {ADAPATER_ASSETS.SATOSHI}
            </Tag>
          </a>
        ));
      },
    },
    {
      title: LIST.TREND,
      render: (_, __) => {
        const variation = __.collateral.assets.reduce(
          (accumulator, element) => {
            const diff = 1 - USD_TO_BTC / element.usdValue;
            accumulator = accumulator + diff;
            return accumulator;
          },
          0
        );
        const icon =
          variation < 0 ? (
            <AiOutlineArrowDown color={"red"} fontSize={12} />
          ) : (
            <AiOutlineArrowUp color={"green"} fontSize={12} />
          );
        return (
          <span>
            {formatter.format(variation)}
            {icon}
          </span>
        );
      },
    },
    {
      title: LIST.EXPIRES,
      dataIndex: "expires",
      align: "right",
      render: () => {
        return moment()
          .add(Math.floor(Math.random() * 25) + 1, "days")
          .format("YYYY-MM-DD");
      },
    },
  ];

  if (loading || error) return <Loading />;

  const { escrows } = data;
  return (
    <Table
      className="custom__table m-top-24"
      size="small"
      dataSource={escrows.data}
      columns={columns}
      rowKey={"id"}
    />
  );
};

function View() {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.RESERVES);
  const LIST = t("LIST", { returnObjects: true });
  return (
    <div className="organization__area">
      <Container>
        <Header
          key={cuid()}
          title={LIST.HEADER.TITLE}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[]}
        />
        <div className="organization__area--statistics">
          {[
            [1, "day", "Ending in 24hs"],
            [7, "days", "Ending in week"],
            [1, "month", "Ending in a month"],
          ].map((filter) => (
            <div
              className="organization__area--statistics--item"
              key={`${filter[0]}-${filter[1]}`}
            >
              <TVL filter={filter} />
            </div>
          ))}
        </div>
        <List />
      </Container>
    </div>
  );
}

export default View;
