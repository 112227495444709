import account from "./account";
import discount from "./discount";
import holding from "./holding";
import position from "./position";
import statistic from "./statistic";
import escrow from "./escrow";
import organization from "./organization";
import webhook from "./webhook";
import signature from "./signature";
import feed from "./feed";
import user from "./user";
import liquidity from "./liquidity";
import product from "./product";
import limit from "./limit";
import limitItem from "./limit-item";
import flowEscrow from "./flow-escrow";
import trail from "./trail";
import flow from "./flow";
import worker from "./worker";

const queries = {
  ...position,
  ...discount,
  ...holding,
  ...account,
  ...statistic,
  ...escrow,
  ...organization,
  ...webhook,
  ...signature,
  ...feed,
  ...user,
  ...liquidity,
  ...product,
  ...limit,
  ...limitItem,
  ...flowEscrow,
  ...trail,
  ...flow,
  ...worker,
};

export default queries;
