import gql from "graphql-tag";

export const CREATE_ASSET = gql`
  mutation CreateAsset($data: AssetDataInput) {
    createAsset(data: $data) {
      id
    }
  }
`;

const assets = { CREATE_ASSET };

export default assets;
