import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Drawer from "shared/components/Drawer";
import { SCREENS } from "utils/constants";

const Summary = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.LOCK.path, {
      state,
    });
  const next = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.BROADCAST.path, {
      state,
    });

  return (
    <Drawer title={""} onClose={cancel}>
      <div className="custom__drawer--head">
        <h3>Finalizing and Signing</h3>
        <p>
          Connect your BTC wallet on which you would like to receive the BTC.
        </p>
      </div>

      <div className="summary__wrapper">
        <div className="summary__wrapper--item">
          <span>Investor</span>
          <p>XYZ Company (Corporate)</p>
        </div>
        <div className="summary__wrapper--item">
          <span>Type of asset</span>
          <p>ORDI</p>
        </div>
        <div className="summary__wrapper--item">
          <span>Locked Amount</span>
          <p>5 BTC</p>
        </div>
        <div className="summary__wrapper--item">
          <span>Next Unlock Date</span>
          <p>2023-12-21</p>
        </div>
      </div>

      <div className="d-flex justify--between align--center m-top-30">
        <Button mode={"default"} onClick={cancel}>
          Back
        </Button>
        <Button mode={"secondary"} onClick={next}>
          Continue
        </Button>
      </div>
    </Drawer>
  );
};

export default Summary;
