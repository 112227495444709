import { useMutation, useQuery } from "@apollo/client";
import { Table, Tag, notification } from "antd";
import graphql from "utils/api/graphql";
import { ACTIONS_TYPES, ASSET_TYPES, TRANSACTION_TYPES } from "utils/constants";
import { isEmpty, startCase, get } from "lodash";
import Button from "shared/components/Button";
import cuid from "cuid";
import { useState } from "react";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useWallet } from "shared/contexts/Wallet";

const FREQUENCIES = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

const Drops = ({ product }) => {
  const { wallet } = useWallet();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.ORDINAL);
  const LIST = t("LIST", { returnObjects: true });
  const [where, setWhere] = useState({
    product: { id: product.id },
  });
  const fetchEscrowsState = useQuery(graphql.queries.ESCROWS, {
    variables: {
      where,
    },
  });

  const [executeClaimFlow] = useMutation(graphql.mutations.EXECUTE_FLOW, {
    onCompleted: () => {
      notification.open({
        duration: 2,
        description: (
          <div className="description">
            <span>Airdrop Executed Successfully</span>
          </div>
        ),
      });
    },
  });

  const [executePrepareFlow] = useMutation(graphql.mutations.EXECUTE_FLOW);

  const items = [
    {
      id: cuid(),
      label: LIST.DROPS.FREQUENCIES.DAILY,
      active: get(where, "meta.period") === FREQUENCIES.DAILY,
      onClick: () =>
        setWhere((_) => ({ ..._, meta: { period: FREQUENCIES.DAILY } })),
    },
    {
      id: cuid(),
      label: LIST.DROPS.FREQUENCIES.WEEKLY,
      active: get(where, "meta.period") === FREQUENCIES.WEEKLY,
      onClick: () =>
        setWhere((_) => ({ ..._, meta: { period: FREQUENCIES.WEEKLY } })),
    },
    {
      id: cuid(),
      label: LIST.DROPS.FREQUENCIES.MONTHLY,
      active: get(where, "meta.period") === FREQUENCIES.MONTHLY,
      onClick: () =>
        setWhere((_) => ({ ..._, meta: { period: FREQUENCIES.MONTHLY } })),
    },
    {
      id: cuid(),
      label: LIST.DROPS.FREQUENCIES.RESET,
      active: isEmpty(where.meta),
      onClick: () => setWhere((_) => ({ ..._, meta: {} })),
    },
  ];

  const columns = [
    {
      title: LIST.DROPS.ID,
      dataIndex: "id",
      render: (item) => item,
    },
    {
      title: LIST.DROPS.REWARD,
      align: "center",
      render: (_, row) => {
        const {
          collateral: { assets },
        } = row;
        return assets
          .filter((asset) => asset.type === ASSET_TYPES.BTC_ADDRESS)
          .map((asset) => {
            return `${asset.content.meta.amount} sats`;
          });
      },
    },
    {
      title: LIST.DROPS.FEE,
      align: "center",
      render: () => {
        return `500 sats`;
      },
    },
    {
      title: LIST.DROPS.FREQUENCY,
      align: "center",
      render: (_, row) => {
        return (
          <Tag
            bordered={false}
            color="geekblue"
            style={{ maxWidth: 100, overflow: "hidden" }}
          >
            {startCase(get(row, "meta.period", FREQUENCIES.DAILY))}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      render: (_, row) => {
        return (
          <Tag
            bordered={false}
            color="warning"
            style={{ maxWidth: 100, overflow: "hidden" }}
          >
            {startCase(row.status)}
          </Tag>
        );
      },
    },
    {
      title: "TX ID",
      align: "center",
      render: (_, row) => {
        const transaction = row.transactions.find(
          (transaction) => transaction.type === TRANSACTION_TYPES.FINALIZED
        );
        const txid = get(transaction, "txid");
        return (
          <Tag style={{ maxWidth: 100, overflow: "hidden" }}>
            <a
              href={`${process.env.REACT_APP_EXPLORER_URL}/tx/${txid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {txid}
            </a>
          </Tag>
        );
      },
    },
    {
      title: LIST.DROPS.ACTIONS.TITLE,
      align: "right",
      render: (_, row) => {
        if (row.status === "completed") return;
        return (
          <div className="d-flex justify--end">
            <Button
              mode={"secondary--light"}
              onClick={async () => {
                const {
                  id,
                  flowEscrow: { flow },
                } = row;
                return executePrepareFlow({
                  variables: {
                    where: { id: flow.id },
                    data: {
                      flowEscrow: { escrow: { id } },
                      state: "prepare",
                      product,
                    },
                  },
                  onCompleted: async (data) => {
                    const { flowEscrows } = data.executeFlow;
                    const { escrow } = flowEscrows.find((flowEscrow) => {
                      return flowEscrow.escrow.id === id;
                    });
                    const {
                      collateral: { assets },
                      transactions,
                    } = escrow;
                    const transaction = transactions.find(
                      (tx) => tx.type === TRANSACTION_TYPES.PARTIALLY_SIGNED
                    );
                    const asset = assets.find(
                      (_) => _.action.type === ACTIONS_TYPES.SWAP
                    );
                    const { base64 } = await wallet.sign(transaction.base64, [
                      {
                        address: asset.content.node.value,
                        signingIndexes: [...Array(transaction.inputs).keys()],
                      },
                    ]);
                    return executeClaimFlow({
                      variables: {
                        where: { id: flow.id },
                        data: {
                          flowEscrow: {
                            escrow: { id, transactions: [{ base64 }] },
                          },
                          state: "claim",
                          product,
                        },
                      },
                    });
                  },
                });
              }}
              disabled={
                !(
                  row.meta.period === FREQUENCIES.DAILY ||
                  (row.meta.period === FREQUENCIES.WEEKLY &&
                    moment()
                      .startOf("day")
                      .isSame(moment().endOf("week").startOf("day"))) ||
                  (row.meta.period === FREQUENCIES.MONTHLY &&
                    moment()
                      .startOf("day")
                      .isSame(moment().endOf("month").startOf("day")))
                )
              }
            >
              {LIST.DROPS.ACTIONS.SIGN_AND_BROADCAST}
            </Button>
          </div>
        );
      },
    },
  ];

  if (fetchEscrowsState.loading || fetchEscrowsState.error) return "Loading";

  const { escrows } = fetchEscrowsState.data;

  return (
    <>
      {items.map((item) => (
        <Tag
          key={item.id}
          onClick={item.onClick}
          color={item.active ? "processing" : ""}
          className="tag--item"
          bordered={false}
        >
          {item.icon}
          <span>{item.label}</span>
        </Tag>
      ))}
      <Table
        className="custom__table m-top-24"
        size="small"
        dataSource={escrows.data}
        columns={columns}
        rowKey={"id"}
      />
    </>
  );
};

export default Drops;
