import { Spin } from "antd";
import { useWindowSize } from "shared/hooks/resize";

const Loading = () => {
  const [height] = useWindowSize();

  return (
    <div
      className={"loading__area"}
      style={{ height: `calc(${height}px / 2)` }}
    >
      <Spin size="large" />
    </div>
  );
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
