import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "utils/constants";
import View from "./View";
import graphql from "utils/api/graphql";
import cuid from "cuid";

const Process = () => {
  const navigate = useNavigate();
  const key = { value: cuid() };
  const cancel = () => navigate(SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.LIST);
  const [createProduct] = useMutation(graphql.mutations.CREATE_PRODUCT, {
    onCompleted: () => {
      return navigate(SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.CREATE.KEY.path, {
        state: { key },
      });
    },
    onError: () => {},
  });
  const onSubmit = (values) => {
    return createProduct({
      variables: {
        data: {
          ...values,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} initialValues={{ key }} />;
};

export default Process;
