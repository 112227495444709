import { useMemo } from "react";
import PropTypes from "prop-types";
import { message, Upload } from "antd";
import Dragger from "./Dragger";
import Uploader from "./Uploader";

const VARIANT = {
  uploader: Uploader,
  dragger: Dragger,
};

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const File = ({ variant, ...props }) => {
  const View = useMemo(() => VARIANT[variant], [variant]);

  const handleFileUpload = async ({ fileList }) => {
    return Promise.all(
      fileList.map(async (file) => {
        const base64 = await convertToBase64(file.originFileObj);
        return base64;
      })
    );
  };

  const beforeUpload = (file) => {
    const isValidFormat =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf";
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidFormat) message.error("VALIDATIONS.FILE.FORMAT");
    if (!isValidSize) message.error("VALIDATIONS.FILE.SIZE");
    return (isValidFormat && isValidSize) || Upload.LIST_IGNORE;
  };

  const customRequest = ({ onSuccess }) => {
    onSuccess(true);
  };

  return (
    <View
      handleFileUpload={handleFileUpload}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      listType="picture"
      {...props}
    />
  );
};

File.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANT)),
};

File.defaultProps = {
  variant: "uploader",
};

export default File;
