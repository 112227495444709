import { createContext, useContext } from "react";
import { signTransaction, getAddress } from "sats-connect";
import { META, NETWORKS, WALLET_PROVIDERS } from "utils/constants";

const Context = createContext("wallet");

export const Consumer = Context.Consumer;

const Wallet = ({ children }) => {
  const provider = WALLET_PROVIDERS.XVERSE;
  const sign = (raw, indexes) => {
    return new Promise((resolve, reject) => {
      const options = {
        payload: {
          network: {
            type: NETWORKS[provider][process.env.REACT_APP_NETWORK],
          },
          message: "Sign Transaction",
          psbtBase64: raw,
          inputsToSign: indexes,
        },
        onFinish: (_) => resolve({ base64: _.psbtBase64 }),
        onCancel: reject,
      };
      signTransaction(options);
    });
  };
  const list = (purpose = META[provider].PURPOSES.PAYMENT) => {
    return new Promise((resolve, reject) => {
      const options = {
        payload: {
          purposes: [purpose],
          message: "Address to fund your lock",
          network: {
            type: NETWORKS[provider][process.env.REACT_APP_NETWORK],
          },
        },
        onFinish: ({ addresses }) => {
          resolve({ addresses });
        },
        onCancel: reject,
      };
      getAddress(options);
    });
  };
  return (
    <Context.Provider value={{ wallet: { sign, list } }}>
      {children}
    </Context.Provider>
  );
};

export default Wallet;

export const useWallet = () => useContext(Context);
