import { gql } from "@apollo/client";

export const DISCOUNT = gql`
  query Discount($where: DiscountWhereInput) {
    discount(where: $where) {
      id
      wallet
    }
  }
`;

const discounts = { DISCOUNT };

export default discounts;
