import axios from "axios";

const API_PATHS = {
  AUTH: {
    SIGN_IN: "/auth/sign-in",
  },
  ORGANIZATIONS: {
    CREATE: "/organizations",
    ACTIVATE: "/organizations/:id/activate",
  },
};

const ID_URL = "https://id.test.deeplake.fi";
//const ID_URL = "http://localhost:3006";

const auth = {
  singIn: (payload) =>
    axios.post(`${ID_URL}${API_PATHS.AUTH.SIGN_IN}`, payload),
};

const organizations = {
  create: (payload) =>
    axios.post(`${ID_URL}${API_PATHS.ORGANIZATIONS.CREATE}`, payload),
};

const id = {
  auth,
  organizations,
};

export default id;
