import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { isEmpty, startCase } from "lodash";
import dayjs from "dayjs";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";
import Card from "shared/components/Card";
import Container from "shared/components/Container";
import Description from "shared/components/Description";
import Heading from "shared/components/Heading";
import Loading from "shared/components/Loading";
import graphql from "utils/api/graphql";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "utils/constants";

const Detail = ({ trail }) => {
  if (isEmpty(trail)) return null;
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div className="m-top-20" />
      <Card type={"simple"} head title={"POST /graphql"}>
        <Description label={"Status"} value={startCase(trail.status)} />
        <Description
          label={"Time"}
          value={dayjs.unix(trail.createdDate / 1000).format(DATE_TIME_FORMAT)}
        />
      </Card>
      <div className="m-top-20" />
      <Card type={"simple"} head title={"Response body"}>
        <div className="timeline__wrapper">
          {trail.footprints.map((footprint) => (
            <div className="timeline__wrapper--item" key={footprint.id}>
              <div className="timeline__wrapper--line">
                <div className="head">
                  <span>{footprint.id}</span>
                  <p>
                    {startCase(footprint.data.key)} /{" "}
                    {startCase(footprint.data.event)}
                  </p>
                </div>
              </div>
              <div className="timeline__wrapper--line">
                <div className="copy">
                    <FiCopy />
                    <small>copy</small> 
                </div>
                <pre>
                  <code>{JSON.stringify(footprint.data.elements[0])}</code>
                </pre>
              </div>
              <div className="timeline__wrapper--line">
                {footprint.data.hint && <div>{footprint.data.hint}</div>}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </Col>
  );
};

const Trail = ({ trail, selected, setSelected }) => {
  return (
    <div
      className={`log__wrapper ${trail.id === selected.id ? "active" : ""}`}
      onClick={() => setSelected(trail)}
    >
      <div className="log__wrapper--head">
        <div className="log__wrapper--item module">{trail.entity}</div>
        <div className="log__wrapper--item status">{trail.status}</div>
        <div className="log__wrapper--item text--right">
          {dayjs.unix(trail.createdDate / 1000).format(DATE_FORMAT)}
        </div>
        <div className="log__wrapper--item text--right">
          {dayjs.unix(trail.createdDate / 1000).format(TIME_FORMAT)}
        </div>
      </div>
    </div>
  );
};

function List() {
  const [selected, setSelected] = useState({});

  const { data, loading, error } = useQuery(graphql.queries.TRAILS, {
    variables: {
      gt: { createdDate: dayjs().startOf("day") },
      lt: { createdDate: dayjs().endOf("day") },
    },
  });

  if (error || loading) return <Loading />;

  const {
    trails: { data: trails },
  } = data;

  return (
    <Container>
      <div className="section--row">
        <Heading title="Trails" />
        <div className="m-top-30" />
        <div className="m-top-30" />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h3 className="title--h3">TODAY</h3>
            {trails.map((trail) => (
              <Trail
                trail={trail}
                key={trail.id}
                {...{ selected, setSelected }}
              />
            ))}
          </Col>
          <Detail trail={selected} />
        </Row>
      </div>
    </Container>
  );
}

export default List;
