import { gql } from "@apollo/client";

export const BOARD = gql`
  query Board {
    board
  }
`;

const statistics = { BOARD };

export default statistics;
