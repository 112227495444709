import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { HttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition, hasDirectives } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { KEYS } from "utils/constants";

export const AUTH_ANNOTATION = "id";

const idLink = new HttpLink({
  uri: process.env.REACT_APP_ID_REST_URL,
});

const updateOperationHeader = (operation, accessToken) =>
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  }));

const TokenInterceptor = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN);
        updateOperationHeader(operation, accessToken);
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
  );

const apiLink = new HttpLink({
  uri: process.env.REACT_APP_API_REST_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_API_WS_URL,
  })
);

const rootLink = split(
  ({ query }) => hasDirectives([AUTH_ANNOTATION], query),
  idLink,

  ApolloLink.from([TokenInterceptor(), apiLink])
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  rootLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
