const Description = ({ label, value }) => {
  return (
    <div className={`description__wrapper`}>
      <div className="description__wrapper--item">{label}</div>
      <div className="description__wrapper--item">{value}</div>
    </div>
  );
};

export default Description;
