import cuid from "cuid";
import Configure from "./Configure";

const drawers = ({ open, enabled, onClose }) => [
  {
    id: cuid(),
    open: open.right,
    side: "right",
    onClose,
    enabled: enabled.configure,
    Widget: () => <Configure {...{ onClose }} />,
  },
];

export default drawers;
