import { SCREENS } from "utils/constants";
import Profile from "./Profile";
import Access from "./Access";
import ConfirmEmail from "./ConfirmEmail";

const routes = [
  {
    path: SCREENS.AUTH.SIGNUP.PROFILE.path,
    exact: true,
    element: <Profile />,
  },
  {
    path: SCREENS.AUTH.SIGNUP.ACCESS.path,
    exact: true,
    element: <Access />,
  },
  {
    path: SCREENS.AUTH.SIGNUP.CONFIRM_EMAIL.path,
    exact: true,
    element: <ConfirmEmail />,
  },
];

export default routes;
