import { gql } from "@apollo/client";

export const WORKERS = gql`
  query Workers($where: WorkerWhereInput) {
    workers(where: $where) {
      data {
        id
        name
        type
        configuration
      }
      count
    }
  }
`;

export const WORKER = gql`
  query Worker($where: WorkerWhereInput) {
    worker(where: $where) {
      id
      name
      type
      configuration
    }
  }
`;

const worker = { WORKERS, WORKER };

export default worker;
