import gql from "graphql-tag";

export const CREATE_FEED = gql`
  mutation CreateFeed($data: FeedDataInput) {
    createFeed(data: $data) {
      id
    }
  }
`;

export const UPDATE_FEED = gql`
  mutation UpdateFeed($where: FeedWhereInput, $data: FeedDataInput) {
    updateFeed(where: $where, data: $data) {
      id
    }
  }
`;

const feeds = { CREATE_FEED, UPDATE_FEED };

export default feeds;
