import cuid from "cuid";
import PropTypes from "prop-types";
import Button from "shared/components/Button";
import View from "./View";

const Component = ({ title, subtitle, actions }) => (
  <View
    title={title}
    subTitle={subtitle}
    headerRight={actions.map(({ onClick, text, disabled = false }) => {
      return (
        <Button
          key={cuid()}
          mode={"secondary"}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </Button>
      );
    })}
  />
);

Component.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      variant: PropTypes.string,
    })
  ),
};

Component.defaultProps = {
  title: "",
  subtitle: "",
  actions: [],
};
export default Component;
