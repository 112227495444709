import { SCREENS } from "utils/constants";
import List from "./List";
import Create from "./Create";

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.LIST,
    exact: true,
    element: <List />,
  },
  ...Create,
];

export default routes;
