import { gql } from "@apollo/client";

export const FEEDS = gql`
  query Feeds($where: FeedWhereInput) {
    feeds(where: $where) {
      data {
        id
        type
      }
      count
    }
  }
`;

export const FEED = gql`
  query Feed($where: FeedWhereInput) {
    feed(where: $where) {
      id
      type
    }
  }
`;

const feed = { FEEDS, FEED };

export default feed;
