import { useMemo } from "react";

import PropTypes from "prop-types";

import Horizontal from "./Horizontal";
import Vertical from "./Vertical";
import Simple from "./Simple";

const TYPE = {
  "horizontal-large": Horizontal.Large,
  "horizontal-small": Horizontal.Small,
  vertical: Vertical,
  simple: Simple,
};

const Card = ({ type, ...props }) => {
  const View = useMemo(() => TYPE[type], [type]);
  return <View {...props} />;
};

Card.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPE)),
};

Card.defaultProps = {
  type: "simple",
};

export default Card;
