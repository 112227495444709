import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import { isNil } from "lodash";
import { HiOutlineKey } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Loading from "shared/components/Loading";
import Drawer from "shared/components/Drawer";
import { useWallet } from "shared/contexts/Wallet";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { SCREENS, TRANSACTION_TYPES } from "utils/constants";

const Broadcast = () => {
  const { wallet } = useWallet();
  const navigate = useNavigate();
  const {
    state: {
      flow: { id },
      product,
    },
  } = useLocation();
  const { data, loading } = useQuery(graphql.queries.FLOW, {
    variables: { where: { id } },
  });
  const [executeFlow] = useMutation(graphql.mutations.EXECUTE_FLOW, {
    onCompleted: (data) => {
      const {
        executeFlow: { flowEscrows },
      } = data;
      const [flowEscrow] = flowEscrows;
      const {
        escrow: { transactions },
      } = flowEscrow;
      const { txid } = transactions.find(
        (transaction) => transaction.type === TRANSACTION_TYPES.FINALIZED
      );
      notification.open({
        message: "New Vesting Schedule",
        description: (
          <div>
            <p style={{ color: "white" }}>
              Schedule Successfully Broadcasted on Chain
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${
                process.env.REACT_APP_EXPLORER_URL
              }/${process.env.REACT_APP_NETWORK.toLocaleLowerCase()}/tx/${txid}`}
            >
              TX in Mempool
            </a>
          </div>
        ),
        duration: 5,
      });
      return navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.TRACE.path, {
        state: { product },
      });
    },
    onError: () => {},
  });

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.TRACE.path, {
      state: { product },
    });

  const sign = async ({ flowEscrow }) => {
    const {
      escrow: {
        transactions,
        collateral: { assets },
      },
    } = flowEscrow;
    const transaction = transactions.find(
      (tx) => tx.type === TRANSACTION_TYPES.PARTIALLY_SIGNED
    );
    const asset = assets[0];
    const { base64 } = await wallet.sign(transaction.base64, [
      {
        address: asset.content.node.value,
        signingIndexes: [...Array(transaction.inputs).keys()],
      },
    ]);
    return executeFlow({
      variables: {
        where: { id },
        data: { transactions: [{ base64 }], state: "broadcast-lock", product },
      },
    });
  };

  if (loading) return <Loading />;

  const {
    flow: { flowEscrows },
  } = data;
  const [flowEscrow] = flowEscrows;

  return (
    <Drawer title={"Sign & Broadcast"} onClose={cancel}>
      <div className="center__area">
        <HiOutlineKey size={64} color={COLORS.C_SECOND} />
        <div className="content">
          <h3>Sign & Broadcast</h3>
          <p>
            This is the final step in creating your Vault & broadcasting to the
            network.
          </p>
        </div>
        <div className="d-flex justify--between align--center">
          <Button mode={"primary"} onClick={cancel}>
            Back
          </Button>
          <Button
            mode={"secondary"}
            disabled={isNil(flowEscrow)}
            onClick={() => sign({ flowEscrow })}
          >
            Sign and Broadcast
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Broadcast;
