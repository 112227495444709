import { Upload } from "antd";
import { BsCloudDownload } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const { Dragger } = Upload;

const Component = ({
  children,
  handleFileUpload,
  onUpload,
  listType,
  ...rest
}) => (
  <Dragger
    onChange={async (info) => {
      const fileList = await handleFileUpload(info);
      onUpload(fileList);
    }}
    listType={listType}
    {...rest}
  >
    <div className="dragger__wrapper--icon">
      <BsCloudDownload size={24} color={COLORS.C_PRIMARY} />
    </div>
    <div className="dragger__wrapper--content">
      <p>Glissez votre fichier ici</p>
      <span className="dragger__wrapper--text">
        ou cliquez pour sélectionner depuis le répertoire
      </span>
    </div>
  </Dragger>
);

export default Component;
