import { gql } from "@apollo/client";

export const HOLDINGS = gql`
  query Holdings($where: HoldingWhereInput) {
    holdings(where: $where) {
      data {
        id
        user
        type
        amount
        collateralization
        status
        holdingId
      }
      count
    }
  }
`;

const holdings = { HOLDINGS };

export default holdings;
