import { SCREENS } from "utils/constants";
import Activate from "./Activate";
import Live from "./Live";

const routes = [
  {
    path: SCREENS.PUBLIC.ORGANIZATIONS.ACTIVATE.path,
    exact: true,
    element: <Activate />,
  },
  {
    path: SCREENS.PUBLIC.ORGANIZATIONS.LIVE.path,
    exact: true,
    element: <Live />,
  },
];

export default routes;
