import { NAME_SPACES } from "shared/locales/constants";
import organization from "./organization";
import webhook from "./webhook";
import feed from "./feed";
import user from "./user";

const translation = {
  [NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION]: organization,
  [NAME_SPACES.PRIVATE.ADMIN.WEBHOOK]: webhook,
  [NAME_SPACES.PRIVATE.ADMIN.FEED]: feed,
  [NAME_SPACES.PRIVATE.ADMIN.USER]: user,
};

export default translation;
