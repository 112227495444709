import Home from "./Home";
import Limits from "./Limits";
import Loans from "./Loans";
import Ordinals from "./Ordinals";
import Products from "./Products";
import Profile from "./Profile";
import Reserves from "./Reserves";
import Users from "./Users";
import Webhooks from "./Webhooks";
import Trails from "./Trails";
import Vesting from "./Vesting";
import Workers from "./Workers";

const routes = [
  ...Home,
  ...Webhooks,
  ...Users,
  ...Reserves,
  ...Ordinals,
  ...Profile,
  ...Products,
  ...Loans,
  ...Limits,
  ...Trails,
  ...Vesting,
  ...Workers,
];

export default routes;
