import { SCREENS } from "utils/constants";
import Create from "./Create";
import Detail from "./Detail";
import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.LIST,
    exact: true,
    element: <List />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.CREATE,
    exact: true,
    element: <Create />,
  },
  {
    path: `${SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.DETAIL}/:id`,
    exact: true,
    element: <Detail />,
  },
];

export default routes;
