import { useEffect } from "react";
import { Col, Row } from "antd";
import { useState } from "react";
import { get } from "lodash";
import axios from "axios";
import Container from "shared/components/Container";
import graphql from "utils/api/graphql";
import { useQuery } from "@apollo/client";
import Loading from "shared/components/Loading";

const CONVERTERS = {
  BTC_TO_SATOSHI: 1e8,
  SATOSHI_TO_SATOSHI: 1e-8,
};

const reward = {
  compute: {
    one: async ({ power = 0.55 }) => {
      const { data: price } = await axios(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
      );
      const {
        data: {
          data: { accountProfits },
        },
      } = await axios.get(
        "https://corsproxy.io/?https%3A%2F%2Fpool.binance.com%2Fmining-api%2Fv1%2Fpublic%2Fpool%2Fprofit%2Fminer%3FobserverToken%3Dm054H0psEUTMwymowb06q86KTPuNQWRzzNjzepBf0d807021%26pageSize%3D10"
      );
      const [accountProfit] = accountProfits.sort((_1, _2) => {
        return _2.time - _1.time;
      });
      const total = accountProfit.profitAmount * CONVERTERS.BTC_TO_SATOSHI;
      const totalNumberOfNfts = 2300;
      const netBtcEarnedFromPool = total * (1 - 0.1);
      const ph = parseFloat(accountProfit.h1d.split(" ")[0]);
      const th = ph * 1000;
      const powerPerTh = 29;
      const btcPrice = price.bitcoin.usd;
      const powerTotalCost =
        (((powerPerTh * th) / 1000) * parseFloat(power) * 24) / btcPrice;
      const drop = Math.floor(
        (netBtcEarnedFromPool - powerTotalCost) / totalNumberOfNfts
      );
      return {
        drop,
        calculation: {
          hashrate: ph,
          totalNumberOfNfts,
          btcEarnedFromPool: total,
          netBtcEarnedFromPool,
          ph,
          th,
          powerPerTh,
          btcPrice,
          powerTotalCost,
          drop,
        },
      };
    },
  },
};

const Distribution = () => {
  const { data, loading, error } = useQuery(graphql.queries.WORKER, {
    variables: { where: {} },
  });
  const [estimation, setEstimation] = useState({
    drop: 0,
    calculation: {
      hashrate: 0,
      totalNumberOfNfts: 0,
      btcEarnedFromPool: 0,
      netBtcEarnedFromPool: 0,
      ph: 0,
      th: 0,
      powerPerTh: 0,
      btcPrice: 0,
      powerTotalCost: 0,
    },
  });

  useEffect(() => {
    const compute = async () => {
      if (get(data, "worker.configuration")) {
        const power = data.worker.configuration.power;
        const { drop, calculation } = await reward.compute.one(power);
        setEstimation({ drop, calculation });
      }
    };
    compute();
  }, [setEstimation, data]);

  if (loading || error) return <Loading />;

  return (
    <Container>
      <Row>
        <Col span={8}>
          <b>Estimated drop</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.drop} (sats)</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Power</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{get(data, "worker.configuration.power")}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Hashrate</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>
          {get(estimation.calculation, "hashrate.avgHashrate", 0)}
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Total Number Of Nfts</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.totalNumberOfNfts}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Today's profit</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.btcEarnedFromPool} (sats)</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>PH</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.ph}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>TH</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.th}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Power Per TH</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.powerPerTh}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>BTC price</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.btcPrice}</Col>
      </Row>
      <Row>
        <Col span={8}>
          <b>Power Total Cost</b>
        </Col>
        <Col span={1}>:</Col>
        <Col span={8}>{estimation.calculation.powerTotalCost}</Col>
      </Row>
    </Container>
  );
};

export default Distribution;
