import { SiOpensourceinitiative } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Drawer from "shared/components/Drawer";
import { useWallet } from "shared/contexts/Wallet";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

const Source = () => {
  const { wallet } = useWallet();
  const navigate = useNavigate();
  const { state } = useLocation();
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.LIST.path, { state });

  const connect = async () => {
    const { addresses } = await wallet.list();
    const [address] = addresses;
    return navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.LOCK.path, {
      state: { ...state, bitcoin: { address } },
    });
  };

  return (
    <Drawer title={"Connect your BTC Wallet"} onClose={cancel}>
      <div className="center__area">
        <SiOpensourceinitiative size={64} color={COLORS.C_SECOND} />
        <div className="content">
          <h3>Connect your BTC Wallet</h3>
          <p>
            Connect your BTC wallet on which you would like to receive the BTC.
          </p>
        </div>
        <div className="d-flex justify--between align--center">
          <Button mode={"default"} onClick={cancel}>
            Back
          </Button>
          <Button mode={"secondary"} onClick={connect}>
            Connect source Address
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Source;
