import { useMutation, useQuery } from "@apollo/client";
import { Button, Col, Input, Popover, Row, Tag } from "antd";
import { flatten } from "lodash";
import { useState } from "react";
import graphql from "utils/api/graphql";
import { LIMIT_TYPES } from "utils/constants";

const Supply = () => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateLimitItem] = useMutation(graphql.mutations.UPDATE_LIMIT_ITEM, {
    onCompleted: () => {
      setOpen(false);
      setValue("");
    },
  });
  const { data, loading, error } = useQuery(graphql.queries.LIMIT_ITEMS, {
    variables: {
      where: { limit: { type: LIMIT_TYPES.INSCRIPTIONS } },
    },
  });

  if (loading || error) return "Loading";

  const { limitItems } = data;
  const indexes = flatten(
    limitItems.data.map((limitItem) => limitItem.value.indexes)
  );

  return (
    <>
      <Popover
        open={open}
        on
        content={
          <Row>
            <Col span={24}>
              <Input
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Button
                    onClick={() => {
                      return updateLimitItem({
                        variables: {
                          where: { id: limitItems.data[0].id },
                          data: {
                            id: limitItems.data[0].id,
                            value: {
                              indexes: [
                                ...limitItems.data[0].value.indexes,
                                parseInt(value),
                              ],
                            },
                          },
                        },
                      });
                    }}
                  >
                    Submit
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        title="Title"
        trigger="click"
      >
        <Button mode={"secondary"} onClick={() => setOpen(true)}>
          Add New
        </Button>
      </Popover>
      <br />
      {indexes.map((index) => (
        <Tag key={index}>{index}</Tag>
      ))}
    </>
  );
};

export default Supply;
