import gql from "graphql-tag";

export const CREATE_ESCROW = gql`
  mutation CreateEscrow($data: EscrowDataInput) {
    createEscrow(data: $data) {
      id
    }
  }
`;

export const EXECUTE_ESCROW = gql`
  mutation ExecuteEscrow($where: EscrowWhereInput) {
    executeEscrow(where: $where) {
      id
      transactions {
        type
        base64
        inputs
        outputs
      }
      collateral {
        assets {
          content {
            meta
            node
          }
          action {
            configuration
          }
        }
      }
    }
  }
`;

export const BROADCAST_ESCROW = gql`
  mutation BroadcastEscrow($data: EscrowDataInput) {
    broadcastEscrow(data: $data) {
      id
    }
  }
`;

const escrows = { CREATE_ESCROW, EXECUTE_ESCROW, BROADCAST_ESCROW };

export default escrows;
