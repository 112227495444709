import { useMutation, useQuery } from "@apollo/client";
import { get, isNil, isEmpty } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import Heading from "shared/components/Heading";
import Loading from "shared/components/Loading";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  ACTIONS_TYPES,
  DATE_FORMAT,
  ESCROW_STATUSES,
  SCREENS,
} from "utils/constants";

const Entry = ({ escrow, product }) => {
  const navigate = useNavigate();
  const [executeEscrow] = useMutation(graphql.mutations.EXECUTE_ESCROW, {
    onCompleted: () => {
      return navigate(
        SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.BROADCAST.path,
        { state: { flow: { id: escrow.flowEscrow.flow.id }, product } }
      );
    },
    onError: () => {},
  });
  const assets = get(escrow, "collateral.assets", []);
  const lock = assets.find((asset) => {
    return (
      asset.action.type === ACTIONS_TYPES.LOCK &&
      moment(asset.action.configuration.paths[0].args[0]).isBefore(moment())
    );
  });
  const data = isNil(lock)
    ? {
        nextUnlockDate: "-",
        lockedAmount: "-",
        disabled: escrow.status === ESCROW_STATUSES.COMPLETED,
      }
    : {
        nextUnlockDate: moment(
          lock.action.configuration.paths[0].args[0]
        ).format(DATE_FORMAT),
        lockedAmount: get(lock, "content.meta.amount"),
        disabled:
          isEmpty(
            get(lock, "action.configuration.paths[0].addresses[0].value")
          ) || escrow.status === ESCROW_STATUSES.COMPLETED,
      };

  return (
    <div key={escrow.id} className="wrapper">
      <small>{escrow.id}</small>
      <div className="list__area--item">
        <small>Investor</small>
        <span>{escrow.meta.description}</span>
      </div>
      <div className="list__area--item">
        <small>Next Unlock Date</small>
        <span>{data.nextUnlockDate}</span>
      </div>
      <div className="list__area--item">
        <small>Asset</small>
        <span>{escrow.meta.asset.toUpperCase()}</span>
      </div>
      <div className="list__area--item">
        <small>Locked Amount</small>
        <span>{data.lockedAmount}</span>
      </div>
      <div className="list__area--item">
        <span
          className={`status ${
            escrow.status === "completed" ? "active" : "pending"
          } `}
        >
          {escrow.status}
        </span>
      </div>
      <div className="list__area--item action">
        <Button
          mode={"secondary"}
          disabled={data.disabled}
          onClick={() =>
            executeEscrow({ variables: { where: { id: escrow.id } } })
          }
        >
          Sign & Broacast
        </Button>
      </div>
    </div>
  );
};

function List() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.VESTING);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading } = useQuery(graphql.queries.ESCROWS, {
    variables: {
      where: {
        product: { id: state.product.id },
      },
    },
  });

  if (loading) return <Loading />;

  const {
    escrows: { data: escrows },
  } = data;

  return (
    <Container>
      <Header
        title={<Heading title={LIST.HEADER.TITLE} />}
        subtitle={LIST.HEADER.SUBTITLE}
        actions={[
          {
            text: LIST.HEADER.NEW,
            onClick: () =>
              navigate(
                SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.SOURCE.path,
                { state }
              ),
          },
        ]}
      />
      <div className="list__area">
        {escrows.map((escrow) => (
          <Entry
            key={escrow.id}
            escrow={escrow}
            product={{ id: state.product.id }}
          />
        ))}
      </div>
    </Container>
  );
}

export default List;
