import cuid from "cuid";
import Supply from "./Supply";
import Stakers from "./Stakers";
import Distribution from "./Distribution";

const drawers = ({ open, enabled, onClose }) => [
  {
    id: cuid(),
    open: open.right,
    side: "right",
    onClose,
    enabled: enabled.distribution,
    Widget: () => <Distribution {...{ onClose }} />,
  },
  {
    id: cuid(),
    open: open.right,
    side: "right",
    onClose,
    enabled: enabled.supply,
    Widget: () => <Supply {...{ onClose }} />,
  },
  {
    id: cuid(),
    open: open.right,
    side: "right",
    onClose,
    enabled: enabled.stakers,
    Widget: () => <Stakers {...{ onClose }} />,
  },
];

export default drawers;
