import { BsPlus } from "react-icons/bs";
import Button from "shared/components/Button";

const Horizontal = {
  Large: ({ image, name, description, onClick }) => {
    return (
      <div className={`card__product`}>
        <img src={image} alt={name} />
        <div className="card__product--content">
          <h3>{name}</h3>
          <p>{description}</p>
          <Button mode={"secondary--light"} size={"small"} onClick={onClick}>
            <BsPlus size={24} /> Enable
          </Button>
        </div>
      </div>
    );
  },
  Small: ({ image, name, onClick }) => {
    return (
      <div className={`card__product--small`} onClick={onClick}>
        <img src={image} alt={name} />
        <div className="content">
          <h3>{name}</h3>
        </div>
      </div>
    );
  },
};

export default Horizontal;
