import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BsUpload } from "react-icons/bs";
import Container from "shared/components/Container";
import Form from "shared/components/Form";
import File from "shared/components/Form/File";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, organization, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.PROFILE);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { name } = organization;

  return (
    <div className="organization__area">
      <Container>
        <Formik
          initialValues={{ name }}
          onSubmit={(values) => {
            return onSubmit(values);
          }}
          validationSchema={validation(
            t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
          )}
        >
          {({ isValid, handleSubmit, ...formProps }) => [
            <Header
              key={"header"}
              title={DETAIL.HEADER.TITLE}
              subtitle={DETAIL.HEADER.ACTION}
              actions={[
                {
                  text: DETAIL.HEADER.DISCARD,
                  onClick: cancel,
                  variant: "outlined",
                },
                {
                  text: DETAIL.HEADER.SAVE,
                  onClick: handleSubmit,
                },
              ]}
            />,
            <Row gutter={[32]}>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 20, offset: 2, order: 1 }}
                lg={{ span: 14, offset: 5, order: 2 }}
              >
                <div className="inputs--container">
                  <div className="inputs--container__header">
                    <span>{FORM.GENERAL_INFO}</span>
                  </div>
                  <Form type={"vertical"}>
                    <div className="field--item">
                      <label>Name</label>
                      <Input
                        placeholder={FORM.NAME}
                        {...bindInputProps({ name: "name", ...formProps })}
                      />
                    </div>
                    <div className="field--item">
                      <File
                        placeholder={FORM.LOGO}
                        type="file"
                        variant="uploader"
                        maxCount={1}
                        onUpload={(fileList) => {
                          formProps.setFieldValue("logo", fileList[0]);
                        }}
                        defaultFileList={[
                          {
                            uid: "1",
                            name: "xxx.png",
                            url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png",
                            percent: 33,
                          },
                        ]}
                      >
                        <div className="field--item--upload">
                          <BsUpload size={24} />
                          <span>Upload Logo</span>
                        </div>
                      </File>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>,
          ]}
        </Formik>
      </Container>
    </div>
  );
};

export default Detail;
