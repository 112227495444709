import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

const ConfirmEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.AUTH);

  return (
    <div className="onboarding__area">
      <div className="onboarding__area--content">
        <div className="head">
          <span>{t("SIGNUP.EMAIL.ALREADY_HAVE_ACCOUNT")}</span>
          <Button
            mode={"secondary--light"}
            onClick={() => {
              return navigate(SCREENS.AUTH.SIGN_IN.path);
            }}
          >
            {t("SIGNUP.EMAIL.NAVIGATION.SIGNIN")}
          </Button>
        </div>
        <div className="form">
          <div
            className="back__arrow"
            onClick={() => navigate(SCREENS.AUTH.SIGNUP.ACCESS.path)}
          >
            <BsArrowLeft size={24} color={COLORS.C_BLACK} />
            <span>{t("SIGNUP.EMAIL.NAVIGATION.BACK")}</span>
          </div>
          <h2 className="title">{t("SIGNUP.EMAIL.TITLE")}</h2>
          <p className="subtitle">
            {t("SIGNUP.EMAIL.SUBTITLE")}{" "}
            <span>{state?.organization.users[0].email}</span>
          </p>
          <div className="send--email">
            <h3>{t("SIGNUP.EMAIL.HEAD")}</h3>
            <p>{t("SIGNUP.EMAIL.SUBHEAD")}</p>
            {/*<Button mode={"secondary"}>{t("SIGNUP.EMAIL.RESEND")}</Button>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
