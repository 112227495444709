import { SCREENS } from "utils/constants";
import Key from "./Key";
import Process from "./Process";

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.CREATE.PROCESS.path,
    exact: true,
    element: <Process />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.CREATE.KEY.path,
    exact: true,
    element: <Key />,
  },
];

export default routes;
