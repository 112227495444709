import gql from "graphql-tag";

export const CREATE_LOAN = gql`
  mutation CreateLoan($data: LoanDataInput) {
    createLoan(data: $data) {
      id
      interestRate
      startDate
      endDate
      status
      borrower {
        id
      }
      lender {
        id
      }
      collateral {
        id
        assets {
          id
        }
      }
      addresses {
        id
      }
    }
  }
`;

const loans = { CREATE_LOAN };

export default loans;
