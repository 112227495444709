import gql from "graphql-tag";

export const CHECK_FUTURE = gql`
  mutation CheckFuture($where: FutureWhereInput) {
    checkFuture(where: $where) {
      id
    }
  }
`;
const futures = { CHECK_FUTURE };

export default futures;
