import { useMutation, useQuery } from "@apollo/client";
import { Col, Row, Popover } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Bitcoin from "shared/assets/images/bitcoin.svg";
import Container from "shared/components/Container";
import Heading from "shared/components/Heading";
import Input from "shared/components/Input";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { LIMIT_TYPES } from "utils/constants";

function List() {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.LIMIT);
  const LIST = t("LIST", { returnObjects: true });
  const [updateLimit] = useMutation(graphql.mutations.UPDATE_LIMIT);
  const { data, loading, error } = useQuery(graphql.queries.LIMITS);

  if (loading || error) return <Loading />;

  const limits = data.limits.data.reduce(
    (accumulator, current) => {
      const selectors = {
        [LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.TICKERS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
        [LIMIT_TYPES.COLLECTIONS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
        [LIMIT_TYPES.INSCRIPTIONS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
      };
      accumulator[current.type] = selectors[current.type](current);
      return accumulator;
    },
    {
      [LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]: { value: null },
      [LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]: { value: null },
      [LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL]: { value: null },
      [LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL]: { value: null },
      [LIMIT_TYPES.TICKERS]: { value: null },
      [LIMIT_TYPES.COLLECTIONS]: { value: null },
      [LIMIT_TYPES.INSCRIPTIONS]: { value: null },
    }
  );
  return (
    <div className="page__area">
      <Container>
        <div className="section--row">
          <Heading title={LIST.HEADER.TITLE} />
        </div>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Input
              defaultValue={limits[LIMIT_TYPES.MAX_AMOUNT_PER_ASSET].value}
              label={
                <span>
                  {LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              suffix={
                <div className="d-flex">
                  <img src={Bitcoin} alt="bitcoin-logo" /> BTC
                </div>
              }
              onChange={(event) => {
                return updateLimit({
                  variables: {
                    where: { id: limits[LIMIT_TYPES.MAX_AMOUNT_PER_ASSET].id },
                    data: {
                      limitItems: [{ value: { amount: event.target.value } }],
                    },
                  },
                });
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Input
              defaultValue={limits[LIMIT_TYPES.MIN_AMOUNT_PER_ASSET].value}
              label={
                <span>
                  {LIST.BODY.ITEMS.MIN_AMOUNT_PER_ASSET}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the minimum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              suffix={
                <div className="d-flex">
                  <img src={Bitcoin} alt="bitcoin-logo" /> BTC
                </div>
              }
              onChange={(event) => {
                return updateLimit({
                  variables: {
                    where: { id: limits[LIMIT_TYPES.MIN_AMOUNT_PER_ASSET].id },
                    data: {
                      limitItems: [{ value: { amount: event.target.value } }],
                    },
                  },
                });
              }}
            />
          </Col>
          {/*<Col xs={24} sm={24} md={12} lg={8}>
            <Input
              defaultValue={
                limits[LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL].value
              }
              label={
                <span>
                  {LIST.BODY.ITEMS.TOTAL_AMOUNT_PER_COLLATERAL}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              suffix={
                <div className="d-flex">
                  <img src={Bitcoin} alt="bitcoin-logo" /> BTC
                </div>
              }
              onChange={(event) => {
                return updateLimit({
                  variables: {
                    where: {
                      id: limits[LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL].id,
                    },
                    data: {
                      limitItems: [{ value: { amount: event.target.value } }],
                    },
                  },
                });
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Input
              defaultValue={limits[LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL].value}
              label={
                <span>
                  {LIST.BODY.ITEMS.MAX_ASSETS_PER_COLLATERAL}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              onChange={(event) => {
                return updateLimit({
                  variables: {
                    where: {
                      id: limits[LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL].id,
                    },
                    data: {
                      limitItems: [{ value: { amount: event.target.value } }],
                    },
                  },
                });
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Select
              mode="multiple"
              label={
                <span>
                  {LIST.BODY.ITEMS.TICKERS}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              defaultValue={[limits[LIMIT_TYPES.TICKERS].value]}
              options={[{ ticker: "btc", inscriptionId: 0 }, ...tickers].map(
                (element) => ({
                  label: element.ticker,
                  value: element.inscriptionId,
                })
              )}
              onChange={(_) => console.log("click")}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Select
              mode="multiple"
              label={
                <span>
                  {LIST.BODY.ITEMS.COLLECTIONS}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              options={collections.map((collection) => ({
                label: collection.name,
                value: collection.symbol,
              }))}
              onChange={(_) => console.log("click")}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Select
              mode="multiple"
              label={
                <span>
                  {LIST.BODY.ITEMS.INSCRIPTIONS}
                  <Popover
                    content={
                      <div>
                        <p>
                          A total BTC amount limiter that limits the maximum
                        </p>
                        <p>
                          amount of BTC that can be moved by one asset in the
                        </p>
                        <p>assets array</p>
                      </div>
                    }
                    title="Information"
                  >
                    {" "}
                    <InfoCircleTwoTone />
                  </Popover>
                </span>
              }
              options={["348020", "352242", "356204"].map((number) => ({
                label: number,
                value: number,
              }))}
              defaultValue={"348020"}
              onChange={(_) => console.log("click")}
            />
            </Col>*/}
        </Row>
      </Container>
    </div>
  );
}

export default List;
