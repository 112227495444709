import { useMutation } from "@apollo/client";
import { isNil } from "lodash";
import { HiOutlineKey } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Drawer from "shared/components/Drawer";
import Input from "shared/components/Input";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import { useState } from "react";

const Notify = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const {
    state: {
      flow: { id },
      product,
    },
  } = useLocation();
  const [executeFlow] = useMutation(graphql.mutations.EXECUTE_FLOW, {
    onCompleted: () =>
      navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.TRACE.path),
    onError: () => {},
  });

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.LOCK.path, {
      state: { product },
    });

  const notify = async () => {
    return executeFlow({
      variables: {
        where: { id },
        data: { state: "notify", product, email },
      },
    });
  };

  return (
    <Drawer title={"Notify Investor"} onClose={cancel}>
      <div className="center__area">
        <HiOutlineKey size={64} color={COLORS.C_SECOND} />
        <div className="content">
          <h3>Notify Investor</h3>
          <p>
            Send an email notification to your investor to sign the contract and
            add his receiving address
          </p>
        </div>
        <div className="d-flex gap-16">
          <Input onChange={(_) => setEmail(_.target.value)} />
        </div>
        <div className="d-flex justify--between align--center">
          <Button mode={"default"} onClick={cancel}>
            Back
          </Button>
          <Button
            mode={"secondary"}
            disabled={isNil(email)}
            onClick={notify}
          >
            Send to Investor
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Notify;
