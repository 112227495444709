import { useContext, useEffect } from "react";
import qs from "qs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Loading";
import { SCREENS } from "utils/constants";
import { StoreContext } from "shared/contexts/store";

const Activate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const { token } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const { setAccessToken } = useContext(StoreContext);

  useEffect(() => {
    const setToken = () => {
      setAccessToken({ accessToken: token });
      return navigate(
        SCREENS.PUBLIC.ORGANIZATIONS.LIVE.path.replace(":id", id)
      );
    };
    return setToken();
  }, [setAccessToken, token, id, navigate]);

  return <Loading />;
};

export default Activate;
