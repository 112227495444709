import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.LIST);
  const [updateWebhook] = useMutation(graphql.mutations.UPDATE_WEBHOOK, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.LIST);
    },
    onError: () => { },
  });
  const { data, loading, error } = useQuery(graphql.queries.WEBHOOK, {
    variables: { where: { id } },
  });

  const onSubmit = ({ organization, ...rest }) => {
    return updateWebhook({
      variables: {
        where: {
          id,
        },
        data: { ...rest, organization: { id: organization } },
      },
    });
  };

  if (loading || error) return "Loading";

  return <View onSubmit={onSubmit} webhook={data.webhook} cancel={cancel} />;
};

export default Detail;
