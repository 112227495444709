import { Select } from "antd";

const { Option } = Select;

const Component = ({ placeholder, defaultValue, items, onChange, ...rest }) => {
  return (
    <Select
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      showArrow={false}
      className="market--select"
      {...rest}
    >
      {items.map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {item.icon && <img src={item.icon} alt={item.name} />}
            <span>{item.name}</span>
          </Option>
        );
      })}
    </Select>
  );
};

export default Component;
