import { BsChevronRight } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const Header = ({ title, subTitle, headerRight }) => {
  return (
    <div className={"breadcrumb"}>
      <div className={"breadcrumb--row"}>
        <div className="title">{title}</div>
        <div className="row">
          {subTitle && [
            <BsChevronRight size={16} color={COLORS.C_GRAY} key={"icon"} />,
            <div key={"subtitle"} className={"subtitle"}>
              {subTitle}
            </div>,
          ]}
        </div>
      </div>
      <div className={"breadcrumb--actions"}>{headerRight}</div>
    </div>
  );
};

Header.propTypes = {};
Header.defaultProps = {};
export default Header;
