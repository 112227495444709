import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.USERS.LIST);
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.USERS.LIST);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.USER, {
    variables: { where: { id } },
  });

  const onSubmit = (data) => {
    return updateUser({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return "Loading";

  return <View onSubmit={onSubmit} user={data.user} cancel={cancel} />;
};

export default Detail;
