import { gql } from "@apollo/client";

export const SIGNATURES = gql`
  query Signatures($where: SignatureWhereInput) {
    signatures(where: $where) {
      data {
        id
        privateKey
        outcome {
          id
        }
      }
    }
  }
`;

const signature = { SIGNATURES };

export default signature;
