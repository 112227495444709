import OrdinalsLendingIcon from "shared/assets/images/products/ordinals.svg";
import BtcLendingIcon from "shared/assets/images/products/btclender.svg";
import BtcStakingIcon from "shared/assets/images/products/btcstaker.svg";
import OrdinalsIcon from "shared/assets/images/products/ordinals.svg";

export const WHITELIST_STATUSES = {
  APPROVED: "approved",
  PENDING: "pending",
};

export const LOCAL_STORAGE_KEYS = {
  PROFILE: "profile",
  ACCESS_TOKEN: "accessToken",
  USER: "user",
};

export const RECAPTCHA_KEY = "6Leasz8iAAAAAJ9BDGs4moUdT1oMuP2158UUqa8N";

export const SCREENS = {
  PROFILE: "/",
  WAITLIST: "/",
  PRIVATE: {
    ADMIN: {
      HOME: "/admin",
      ORGANIZATIONS: {
        LIST: "/admin/organizations",
        CREATE: "/admin/organizations/create",
        DETAIL: "/admin/organizations/detail",
      },
      WEBHOOKS: {
        LIST: "/admin/webhooks",
        CREATE: "/admin/webhooks/create",
        DETAIL: "/admin/webhooks/detail",
      },
      SIGNATURES: {
        LIST: "/admin/signatures",
      },
      FEEDS: {
        LIST: "/admin/feeds",
        CREATE: "/admin/feeds/create",
        DETAIL: "/admin/feeds/detail",
      },
      USERS: {
        LIST: "/admin/users",
        CREATE: "/admin/users/create",
        DETAIL: "/admin/users/detail",
      },
    },
    ORGANIZATION: {
      HOME: "/organization",
      PRODUCTS: {
        LIST: "/organization/products",
        CREATE: {
          PROCESS: {
            path: "/organization/products/create/process",
            breadcrumb: [],
          },
          KEY: { path: "/organization/products/create/key", breadcrumb: [] },
        },
      },
      WORKERS: {
        LIST: { path: "/organization/workers", breadcrumb: [] },
      },
      WEBHOOKS: {
        LIST: "/organization/webhooks",
        CREATE: "/organization/webhooks/create",
        DETAIL: "/organization/webhooks/detail",
      },
      SIGNATURES: {
        LIST: "/organization/signatures",
      },
      USERS: {
        LIST: "/organization/users",
        CREATE: "/organization/users/create",
        DETAIL: "/organization/users/detail",
      },
      RESERVES: {
        LIST: "/organization/reserves",
      },
      ORDINALS: {
        LIST: "/organization/ordinals",
        TRACE: { path: "/organization/ordinals/trace", breadcrumb: [] },
      },
      VESTING: {
        LIST: { path: "/organization/vesting", breadcrumb: [] },
        TRACE: { path: "/organization/vesting/trace", breadcrumb: [] },
        SECHEDULE: {
          SOURCE: {
            path: "/organization/vesting/schedule/source",
            breadcrumb: [],
          },
          LOCK: {
            path: "/organization/vesting/schedule/lock",
            breadcrumb: [],
          },
          NOTIFY: {
            path: "/organization/vesting/schedule/notify",
            breadcrumb: [],
          },
          FINALIZE: {
            path: "/organization/vesting/schedule/finalize",
            breadcrumb: [],
          },
          SUMMARY: {
            path: "/organization/vesting/schedule/summary",
            breadcrumb: [],
          },
          BROADCAST: {
            path: "/organization/vesting/schedule/broadcast",
            breadcrumb: [],
          },
          SUCCESS: {
            path: "/organization/vesting/schedule/success",
            breadcrumb: [],
          },
        },
      },
      PROFILE: {
        DETAIL: "/organization/organizations/detail",
      },
      LAONS: {
        BTC: {
          LIST: "/organization/btc/loans",
        },
      },
      LIMITS: {
        LIST: "/organization/limits",
      },
      TRAILS: {
        LIST: { path: "/organization/trails", breadcrumb: [] },
      },
    },
  },
  PUBLIC: {
    INDEX: "/",
    RESERVES: {
      LIST: { path: "/reserves", breadcrumb: [] },
    },
    ORGANIZATIONS: {
      ACTIVATE: { path: "/organizations/:id/activate", breadcrumb: [] },
      LIVE: { path: "/organizations/:id/live", breadcrumb: [] },
    },
    SIGNATURES: {
      EXECUTE: { path: "/signatures/execute", breadcrumb: [] },
    },
    ONBOARDING: {
      CREATE: { path: "/onboarding", breadcrumb: [] },
      FORM: { path: "/onboarding/infos", breadcrumb: [] },
      SEND: { path: "/onboarding/mail", breadcrumb: [] },
    },
  },
  AUTH: {
    LOGOUT: { path: "/auth/logout", breadcrumb: [] },
    SIGN_IN: { path: "/signin", breadcrumb: [] },
    SIGNUP: {
      PROFILE: { path: "/signup/profile", breadcrumb: [] },
      ACCESS: { path: "/signup/access", breadcrumb: [] },
      CONFIRM_EMAIL: { path: "/signup/confirm-email", breadcrumb: [] },
    },
  },
};

export const NETWORK_ADDRESS_ACCESSOR = {
  DEVNET: "testnet",
  TESTNET: "testnet",
  MAINNET: "mainnet",
};

export const CURRENT_ENV = "TESTNET";

export const GO_BACKS = {
  ONE: -1,
  TWO: -2,
};

export const COINS = {
  BITCOIN: "bitcoin",
};

export const DEFAULT_USER_MARKET = COINS.BITCOIN;

export const ADAPATER_ASSETS = {
  SATOSHI: "satoshi",
  USTX: "ustx",
};

export const MARKETS = {
  [COINS.BITCOIN]: {
    id: COINS.BITCOIN,
    underlying: { id: COINS.BITCOIN, ratio: 1 },
    name: "BTC PERP",
    sides: {
      [true]: "L_BTC",
      [false]: "L_BTC",
    },
    SUPPORT_CONFIG: {
      1: { min: 1 },
      5: { min: 1 },
      10: { min: 1 },
      25: { min: 1 },
    },
  },
  [COINS.STX]: {
    id: COINS.STX,
    underlying: { id: COINS.STX, ratio: 1 },
    name: "STX PERP",
    sides: {
      [true]: "L_STX",
      [false]: "L_STX",
    },
    SUPPORT_CONFIG: {
      1: { min: 1000 },
      5: { min: 10000 },
      10: { min: 10000 },
      25: { min: 10000 },
    },
  },
};

export const COIN_GECKO = {
  BASE_URL: "https://api.coingecko.com/api/v3/coins",
  COINS: {
    [COINS.BITCOIN]: "bitcoin",
    [COINS.STX]: "blockstack",
    [COINS.HASH]: "bithash-token",
  },
};

export const FINNHUB = {
  BASE_URL: "https://finnhub.io/api/v1/crypto",
  TOKEN: "cc9qptqad3i82ecrdd10",
  COINS: {
    [COINS.BITCOIN]: "BINANCE:BTCUSDT",
    [COINS.STX]: "BINANCE:STXUSDT",
  },
  RESOLUTION: "1",
  GAP: 3 * 60 * 60,
};

export const ADDRESS_TYPES = {
  FEE: "fee",
  CHANGE: "change",
  RECEIVE: "receive",
  REPAYMENT: "repayment",
  LIQUIDATION: "liquidation",
};

export const ASSET_TYPES = {
  BTC_ADDRESS: "btc.address",
  BTC_UTXO: "btc.utxo",
};

export const LIMIT_TYPES = {
  MAX_AMOUNT_PER_ASSET: "max-amount-per-asset",
  MIN_AMOUNT_PER_ASSET: "min-amount-per-asset",
  TOTAL_AMOUNT_PER_COLLATERAL: "total-amount-per-collateral",
  MAX_ASSETS_PER_COLLATERAL: "max-assets-per-collateral",
  TICKERS: "tickers",
  COLLECTIONS: "collections",
  INSCRIPTIONS: "inscriptions",
};

export const DISABLE_FETCH_PRICES = true;

export const KEYS = {
  ACCESS_TOKEN: "accessToken",
  USER: "user",
};

export const USER_TYPES = {
  ORGANIZATION_ADMIN: {
    type: "org-admin",
    index: SCREENS.PRIVATE.ORGANIZATION.HOME,
  },

  ADMIN: { type: "admin", index: SCREENS.PRIVATE.ADMIN.HOME },
};

export const PROFILES = {
  [USER_TYPES.ADMIN.type]: {
    key: "admin",
    name: "Admin",
  },
  [USER_TYPES.ORGANIZATION_ADMIN.type]: {
    key: "organization",
    name: "Organization",
  },
};

export const WALLET_PROVIDERS = {
  XVERSE: "xverse",
  HIRO: "hiro",
  LEDGER: "ledger",
};

export const META = {
  [WALLET_PROVIDERS.XVERSE]: {
    PURPOSES: { PAYMENT: "payment", ORDINALS: "ordinals" },
  },
};

export const NETWORKS = {
  [WALLET_PROVIDERS.XVERSE]: {
    TESTNET: "Testnet",
    BITCOIN: "Mainnet",
  },
};

export const TRANSACTION_TYPES = {
  PARTIALLY_SIGNED: "partially-signed",
  FINALIZED: "finalized",
};

export const ACTIONS_TYPES = {
  AIRDROP: "airdrop",
  SWAP: "swap",
  LOCK: "lock",
  UNLOCK: "unlock",
  FEE: "fee",
};

export const FNS = {
  IDENTITY: "identity",
  TIME: "time",
};

export const PAGINATION = {
  SMALL: {
    SKIP: 0,
    TAKE: 10,
  },
  MEDIUM: {
    SKIP: 0,
    TAKE: 100,
  },
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD MMM. YYYY HH:mm";
export const TIME_FORMAT = "HH:mm:ss";

export const PRODUCT_TYPES = {
  ORDINALS_LENDER: {
    value: "ordinals.lender",
    label: "Ordinals Lending",
    icon: OrdinalsLendingIcon,
  },
  BTC_LENDER: {
    value: "btc.lender",
    label: "BTC Lending",
    icon: BtcLendingIcon,
  },
  BTC_STAKER: {
    value: "btc.staker",
    label: "BTC Staking",
    icon: BtcStakingIcon,
  },
  BTC_AIRDROP: {
    value: "btc.airdrop",
    label: "BTC Airdrop",
    icon: BtcStakingIcon,
  },
  ORDINALS_STAKER: {
    value: "ordinals.staker",
    label: "Ordinals Staking",
    icon: OrdinalsIcon,
  },
  ORDINALS_OFFERS: {
    value: "ordinals.offers",
    label: "Ordinals Offers",
    icon: BtcStakingIcon,
  },
  LOCKER: { value: "locker", label: "Ordinals Vaults", icon: BtcStakingIcon },
  AUCTION: { value: "auction", label: "Auctions", icon: BtcStakingIcon },
  VESTING: { value: "vesting", label: "Vesting", icon: BtcStakingIcon },
};

export const ESCROW_STATUSES = {
  PENDING: "pending",
  COMPLETED: "completed",
};

export const WORKER_TYPES = {
  AIRDROP: "airdrop",
};
