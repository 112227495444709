import { Drawer } from "antd";
import { createContext, useContext, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const Context = createContext("drawers");

export const Consumer = Context.Consumer;

const Drawers = ({ children }) => {
  const [items, setItems] = useState([]);
  return (
    <Context.Provider value={{ drawers: { setItems } }}>
      {children}
      {items.map(({ Widget, id, open, onClose }) => {
        return (
          <Drawer
            size="large"
            key={id}
            className="custom__drawer"
            title={""}
            placement="right"
            closable
            closeIcon={<BsArrowLeft size={24} color={COLORS.C_DARK} />}
            onClose={onClose}
            open={open}
          >
            <Widget key={id} />
          </Drawer>
        );
      })}
    </Context.Provider>
  );
};

export default Drawers;

export const useDrawers = () => useContext(Context);
