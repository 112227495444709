import gql from "graphql-tag";

export const EXECUTE_OUTCOME = gql`
  mutation ExecuteOutcome($where: OutcomeWhereInput, $data: OutcomeDataInput) {
    executeOutcome(where: $where, data: $data) {
      id
      cet {
        transactions {
          base64
          type
        }
      }
    }
  }
`;

const outcomes = { EXECUTE_OUTCOME };

export default outcomes;
