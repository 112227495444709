import { SCREENS } from "utils/constants";
import Lock from "./Lock";
import Notify from "./Notify";
import Finalize from "./Finalize";
import Broadcast from "./Broadcast";
import Summary from "./Summary";
import Source from "./Source";
import Success from "./Success";

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.SOURCE.path,
    exact: true,
    element: <Source />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.LOCK.path,
    exact: true,
    element: <Lock />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.NOTIFY.path,
    exact: true,
    element: <Notify />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.FINALIZE.path,
    exact: true,
    element: <Finalize />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.SUMMARY.path,
    exact: true,
    element: <Summary />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.BROADCAST.path,
    exact: true,
    element: <Broadcast />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.SECHEDULE.SUCCESS.path,
    exact: true,
    element: <Success />,
  },
];

export default routes;
