import { useQuery } from "@apollo/client";
import { Select } from "antd";
import { get } from "lodash";
import PropTypes from "prop-types";

const { Option } = Select;

const Component = ({
  defaultValue,
  onChange = () => {},
  onSearch = () => {},
  format = () => {},
  placeholder,
  node,
  ...rest
}) => {
  const [query, options, accessor] = node;
  const { data, loading } = useQuery(query, options);
  const items = get(data, accessor, []);
  return (
    <Select
      loading={loading}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      showArrow={false}
      className="market--select"
      showSearch
      onSearch={onSearch}
      {...rest}
    >
      {items.map((item) => {
        return (
          <Option value={item.id} key={item.id}>
            {format(item)}
          </Option>
        );
      })}
    </Select>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  items: PropTypes.object,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  placeholder: "",
};

export default Component;
