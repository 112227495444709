import auth from "./auth";
import welcome from "./welcome";
import notification from "./notification";
import unauthenticated from "./unauthenticated";
import authenticated from "./authenticated";
import layout from "./layout";

export const frTranslation = {
  auth,
  welcome,
  notification,
  ...layout,
  ...unauthenticated,
  ...authenticated,
};
