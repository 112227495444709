import { gql } from "@apollo/client";

export const TVL = gql`
  query Tvl($where: LiquidityWhereInput, $lt: LiquidityWhereInput) {
    tvl(where: $where, lt: $lt) {
      amount
    }
  }
`;

const liquidity = { TVL };

export default liquidity;
