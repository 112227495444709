import { SCREENS } from "utils/constants";
import List from "./List";
import Schedule from "./Schedule";
import Trace from "./Trace";

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.LIST.path,
    exact: true,
    element: <List />,
  },
  {
    path: SCREENS.PRIVATE.ORGANIZATION.VESTING.TRACE.path,
    exact: true,
    element: <Trace />,
  },
  ...Schedule,
];

export default routes;
