import { ApolloProvider } from "@apollo/client";
//import "antd/dist/antd.min.css";
import { Buffer } from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import WalletProvider from "shared/contexts/Wallet";
import DrawersProvider from "shared/contexts/Drawers";
import { GlobalSubscriptionContext } from "shared/services/subscriptions";
import { StoreProvider } from "shared/contexts/store";

import client from "shared/graphql";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./shared/locales/i18n";
import "./shared/style/style.scss";
// @ts-ignore

window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GlobalSubscriptionContext client={client}>
        <StoreProvider>
          <HashRouter>
            <DrawersProvider>
              <WalletProvider>
                <App />
              </WalletProvider>
            </DrawersProvider>
          </HashRouter>
        </StoreProvider>
      </GlobalSubscriptionContext>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
