import gql from "graphql-tag";

export const ACTIVATE_ORGANIZATION = gql`
  mutation ActivateOrganization($where: OrganizationWhereInput) {
    activateOrganization(where: $where) {
      id
    }
  }
`;

const organizations = { ACTIVATE_ORGANIZATION };

export default organizations;
