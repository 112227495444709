import gql from "graphql-tag";

export const CREATE_WEBHOOK = gql`
  mutation CreateWebhook($data: WebhookDataInput) {
    createWebhook(data: $data) {
      id
    }
  }
`;

export const UPDATE_WEBHOOK = gql`
  mutation UpdateWebhook($where: WebhookWhereInput, $data: WebhookDataInput) {
    updateWebhook(where: $where, data: $data) {
      id
    }
  }
`;

const webhooks = { CREATE_WEBHOOK, UPDATE_WEBHOOK };

export default webhooks;
