import cuid from "cuid";
import get from "lodash/get";
import { useContext } from "react";
import { Dropdown } from "antd";
import { useNavigate, useRoutes, Link } from "react-router-dom";
import { FiBell, FiBriefcase, FiLogOut, FiSettings } from "react-icons/fi";
import screens from "screens";
import Trails from "shared/assets/images/menu/direct.svg";
import Workers from "shared/assets/images/menu/brief-case.svg";
import Building from "shared/assets/images/menu/building-1.svg";
import Candle from "shared/assets/images/menu/candle.svg";
import Feeds from "shared/assets/images/menu/feeds.svg";
import Global from "shared/assets/images/menu/global.svg";
import Home from "shared/assets/images/menu/home.svg";
import Limits from "shared/assets/images/menu/setting.svg";
import Users from "shared/assets/images/menu/users.svg";
import MenuItem from "shared/components/MenuItem";
import Layout from "shared/components/Layout";
import { StoreContext } from "shared/contexts/store";
import { PROFILES, SCREENS, USER_TYPES } from "utils/constants";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { startCase } from "lodash";

const links = {
  [PROFILES[USER_TYPES.ADMIN.type].key]: [
    {
      to: SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST,
      element: <MenuItem label="Organizations" icon={Building} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ADMIN.FEEDS.LIST,
      element: <MenuItem label="Feeds" icon={Feeds} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ADMIN.USERS.LIST,
      element: <MenuItem label="Users" icon={Users} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ADMIN.WEBHOOKS.LIST,
      element: <MenuItem label="Webhooks" icon={Global} />,
      id: cuid(),
    },
  ],
  [PROFILES[USER_TYPES.ORGANIZATION_ADMIN.type].key]: [
    {
      to: SCREENS.PRIVATE.ORGANIZATION.HOME,
      element: <MenuItem label="Home" icon={Home} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.LIST,
      element: <MenuItem label="Products" icon={Candle} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.LIMITS.LIST,
      element: <MenuItem label="Limits" icon={Limits} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.ORDINALS.TRACE.path,
      element: <MenuItem label="Ordinals" icon={Feeds} />,
      id: cuid(),
    },
    /* {
      to: SCREENS.PRIVATE.ORGANIZATION.VESTING.TRACE.path,
      element: <MenuItem label="Vesting" icon={Feeds} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.USERS.LIST,
      element: <MenuItem label="Users" icon={Users} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.WEBHOOKS.LIST,
      element: <MenuItem label="Webhooks" icon={Global} />,
      id: cuid(),
    },*/
    {
      to: SCREENS.PRIVATE.ORGANIZATION.TRAILS.LIST.path,
      element: <MenuItem label="Trails" icon={Trails} />,
      id: cuid(),
    },
    {
      to: SCREENS.PRIVATE.ORGANIZATION.WORKERS.LIST.path,
      element: <MenuItem label="Workers" icon={Workers} />,
      id: cuid(),
    },
  ],
  [undefined]: [],
};

const drawers = () => ({
  [PROFILES[USER_TYPES.ADMIN.type].key]: [],
  [PROFILES[USER_TYPES.ORGANIZATION_ADMIN.type].key]: [],
  [undefined]: [],
});

const App = () => {
  const { user, accessToken } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const key = get(get(PROFILES, `${get(user, "meta.type")}`), "key");
  const navigate = useNavigate();
  const logout = () => {
    console.log("object :>> ", SCREENS.AUTH.LOGOUT.path);
    return navigate(SCREENS.AUTH.LOGOUT.path);
  };

  const menu = [
    ...(accessToken
      ? [...links[key], ...links[undefined]]
      : [...links[undefined]]),
  ].filter((x) => x);

  const actions = [
    () => (
      <Link>
        <FiBell />
      </Link>
    ),
    () => (
      <Link className="link">
        <FiSettings />
      </Link>
    ),
    () => (
      <Dropdown
        menu={{
          items: [
            {
              key: 1,
              label: (
                <Link
                  className="d-flex"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <FiLogOut /> <small>{t("LOGOUT")}</small>
                </Link>
              ),
            },
          ],
        }}
        trigger={["click"]}
        placement="bottomRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Link
          className="link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <FiBriefcase />
          <small>{startCase(user.organization.name)}</small>
        </Link>
      </Dropdown>
    ),
  ];
  const layouts = {
    [true]: {
      element: Layout,
      menu,
      actions,
      drawers: drawers()[key],
    },
    [false]: { element: () => <></> },
  };
  return useRoutes(screens({ user, layouts }));
};

export default App;
