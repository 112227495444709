import { Drawer as AntdDrawer } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const Sidebar = ({ children, onClose, ...rest }) => {
  return (
    <AntdDrawer
      size="large"
      className="custom__drawer"
      placement="right"
      closable
      closeIcon={<BsArrowLeft size={24} color={COLORS.C_DARK} />}
      onClose={onClose}
      open={true}
      {...rest}
    >
      {children}
    </AntdDrawer>
  );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
