import { SCREENS } from "utils/constants";

function Home() {
  return <div className="page__area">Admin Home</div>;
}

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.HOME,
    exact: true,
    element: <Home />,
  },
];

export default routes;
