import gql from "graphql-tag";

export const CREATE_WORKER = gql`
  mutation CreateWorker($data: WorkerDataInput) {
    createWorker(data: $data) {
      id
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation UpdateWorker($where: WorkerWhereInput, $data: WorkerDataInput) {
    updateWorker(where: $where, data: $data) {
      id
    }
  }
`;

const workers = { CREATE_WORKER, UPDATE_WORKER };

export default workers;
