import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Loading";
import graphql from "utils/api/graphql";
import { GO_BACKS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [updateOrganization] = useMutation(
    graphql.mutations.UPDATE_ORGANIZATION,
    {
      onCompleted: () => {
        navigate(GO_BACKS.ONE);
      },
      onError: () => { },
    }
  );
  const { data, loading, error } = useQuery(graphql.queries.ORGANIZATION, {
    variables: { where: { id } },
  });

  const onSubmit = (data) => {
    return updateOrganization({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return <View onSubmit={onSubmit} organization={data.organization} />;
};

export default Detail;
