import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";

function List() {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.WEBHOOK);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.WEBHOOKS, {
    variables: { where: {} },
  });
  const columns = [
    {
      title: LIST.ID,
      dataIndex: "id",
      render: (item) => item,
    },
    {
      title: LIST.NAME,
      dataIndex: "name",
      render: (item) => item,
    },
    {
      title: LIST.DESCRIPTION,
      dataIndex: "description",
      render: (item) => item,
    },
    {
      title: LIST.URL,
      dataIndex: "url",
      render: (item) => item,
    },
    {
      title: LIST.ORGANIZATION,
      dataIndex: "organization.name",
      render: (_, __) => <Tag>{__.organization.name}</Tag>,
    },
    {
      title: LIST.ACTIONS,
      key: "actions",
      render: (_, __) => {
        return (
          <AiFillEye
            color={COLORS.C_GRAY_L2}
            size={18}
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.WEBHOOKS.DETAIL}/${__.id}`)
            }
          />
        );
      },
    },
  ];

  if (loading || error) return "Loading";

  const { webhooks } = data;
  return (
    <div className="page__area">
      <Container>
        <Header
          key={cuid()}
          title={LIST.HEADER.TITLE}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[
            {
              text: LIST.HEADER.NEW,
              onClick: () => navigate(SCREENS.PRIVATE.ADMIN.WEBHOOKS.CREATE),
            },
          ]}
        />
        <Table
          className="custom__table"
          size="small"
          dataSource={webhooks.data}
          columns={columns}
          rowKey={"id"}
        />
      </Container>
    </div>
  );
}

export default List;
