import PropTypes from "prop-types";

const Component = ({ title, subtitle, children }) => {
  return (
    <div className="form-vertical__wrapper">
      {title && (
        <div className="form-vertical__title">
          <h3>{title}</h3>
          <span>{subtitle}</span>
        </div>
      )}

      <div className="form-vertical__content">{children}</div>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default Component;
