import { Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { StoreContext } from "shared/contexts/store";
import { SCREENS } from "utils/constants";

const Redirect = () => {
  const { clearStore } = useContext(StoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    const logout = () => {
      clearStore(() => navigate(SCREENS.PUBLIC.INDEX));
    };
    logout();
  }, [clearStore, navigate]);

  return (
    <div>
      <Spin />
    </div>
  );
};

export default Redirect;
