import gql from "graphql-tag";

export const EXECUTE_FLOW = gql`
  mutation ExecuteFlow($where: JSONObject, $data: JSONObject) {
    executeFlow(where: $where, data: $data) {
      id
      flowEscrows {
        id
        escrow {
          id
          transactions {
            type
            base64
            inputs
            outputs
            txid
          }
          collateral {
            assets {
              id
              content {
                meta
                node
              }
              action {
                id
                type
                configuration
              }
            }
          }
        }
      }
    }
  }
`;

const flows = { EXECUTE_FLOW };

export default flows;
