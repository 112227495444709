import { gql } from "@apollo/client";

export const USERS = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      data {
        id
        lastname
        firstname
        email
        phone
        meta
      }
      count
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereInput) {
    user(where: $where) {
      id
      lastname
      firstname
      email
      phone
      meta
    }
  }
`;

export const PROFILE = gql`
  query Profile {
    profile {
      id
      lastname
      firstname
      email
      phone
      meta
      organization {
        name
        id
      }
    }
  }
`;

const user = { USERS, USER, PROFILE };

export default user;
