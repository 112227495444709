import { useMutation } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "utils/constants";
import View from "./View";
import graphql from "utils/api/graphql";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.WEBHOOKS.LIST);
  const [createWebhook] = useMutation(graphql.mutations.CREATE_WEBHOOK, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.WEBHOOKS.LIST);
    },
    onError: () => {},
  });
  const onSubmit = (values) => {
    return createWebhook({
      variables: {
        data: {
          ...values,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
