import discount from "./discount";
import escrow from "./escrow";
import organization from "./organization";
import webhook from "./webhook";
import future from "./future";
import loan from "./loan";
import address from "./address";
import asset from "./asset";
import feed from "./feed";
import user from "./user";
import product from "./product";
import action from "./action";
import outcome from "./outcome";
import limit from "./limit";
import limitItem from "./limit-item";
import flow from "./flow";
import worker from "./worker";

const mutations = {
  ...discount,
  ...escrow,
  ...organization,
  ...webhook,
  ...future,
  ...loan,
  ...address,
  ...asset,
  ...feed,
  ...user,
  ...product,
  ...action,
  ...outcome,
  ...limit,
  ...flow,
  ...limitItem,
  ...worker,
};

export default mutations;
