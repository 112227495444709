import gql from "graphql-tag";

export const CREATE_LIMIT_ITEM = gql`
  mutation CreateLimitItem($data: LimitItemDataInput) {
    createLimitItem(data: $data) {
      id
    }
  }
`;

export const UPDATE_LIMIT_ITEM = gql`
  mutation UpdateLimitItem(
    $where: LimitItemWhereInput
    $data: LimitItemDataInput
  ) {
    updateLimitItem(where: $where, data: $data) {
      id
    }
  }
`;

const limitItem = { CREATE_LIMIT_ITEM, UPDATE_LIMIT_ITEM };

export default limitItem;
