import { NAME_SPACES } from "shared/locales/constants";
import webhook from "./webhook";
import user from "./user";
import reserves from "./reserves";
import ordinal from "./ordinal";
import profile from "./profile";
import product from "./product";
import loan from "./loan";
import limit from "./limit";
import vesting from "./vesting";
import worker from "./worker";
import home from "./home";

const translation = {
  [NAME_SPACES.PRIVATE.ORGANIZATION.WEBHOOK]: webhook,
  [NAME_SPACES.PRIVATE.ORGANIZATION.USER]: user,
  [NAME_SPACES.PRIVATE.ORGANIZATION.RESERVES]: reserves,
  [NAME_SPACES.PRIVATE.ORGANIZATION.ORDINAL]: ordinal,
  [NAME_SPACES.PRIVATE.ORGANIZATION.PROFILE]: profile,
  [NAME_SPACES.PRIVATE.ORGANIZATION.PRODUCT]: product,
  [NAME_SPACES.PRIVATE.ORGANIZATION.LOAN]: loan,
  [NAME_SPACES.PRIVATE.ORGANIZATION.LIMIT]: limit,
  [NAME_SPACES.PRIVATE.ORGANIZATION.VESTING]: vesting,
  [NAME_SPACES.PRIVATE.ORGANIZATION.WORKER]: worker,
  [NAME_SPACES.PRIVATE.ORGANIZATION.HOME]: home,
};

export default translation;
