import { useMemo } from "react";

import PropTypes from "prop-types";

import Highlight from "./Highlight";
import Horizontal from "./Horizontal";
import Vertical from "./Vertical";

const MODES = {
  horizontal: Horizontal,
  vertical: Vertical,
  highlight: Highlight,
};

const Form = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Form.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Form.defaultProps = {
  type: "horizontal",
};

export default Form;
