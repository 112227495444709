import { gql } from "@apollo/client";

export const TRAILS = gql`
  query Trails($where: TrailWhereInput, $skip: Int, $take: Int) {
    trails(where: $where, skip: $skip, take: $take) {
      data {
        id
        status
        entity
        createdDate
        footprints {
          id
          data
        }
      }
      count
    }
  }
`;

const escrow = { TRAILS };

export default escrow;
