import { Select } from "antd";
import classNames from "classnames";
import Label from "shared/components/Label";

const Simple = ({
  label: selectLabel,
  node,
  errors,
  icon,
  labels,
  onChange = () => {},
  onSearch = () => {},
  touched,
  items,
  ...rest
}) => {
  return (
    <div
      className={classNames("select__wrapper", {
        "--error": touched && errors,
      })}
    >
      <Label label={selectLabel} icon={icon} />
      <div className={`select__wrapper--field`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          onChange={onChange}
          showSearch
          onSearch={onSearch}
          options={items}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
