import Routes from "shared/components/Routes";
import Auth from "./Auth";
import Private from "./Private";
import Public from "./Public";

const screens = ({ user, layouts }) => {
  return [
    ...[...Private].map(({ element, path, ...rest }) => {
      const Layout = layouts[true].element;
      const menu = layouts[true].menu;
      const actions = layouts[true].actions;
      const drawers = layouts[true].drawers;
      const Widget = Routes.private({
        component: () => (
          <Layout menu={menu} actions={actions} drawers={drawers}>
            {element}
          </Layout>
        ),
        path,
      });
      return {
        element: <Widget />,
        path,
        ...rest,
      };
    }),
    ...Auth,
    ...Public,
  ];
};

export default screens;
