import { gql } from "@apollo/client";

export const FLOW = gql`
  query Flow($where: FlowWhereInput) {
    flow(where: $where) {
      id
      flowEscrows {
        escrow {
          id
          meta
          endDate
          transactions {
            id
            hex
            base64
            type
          }
          collateral {
            assets {
              usdValue
              id
              type
              content {
                meta
                node
              }
              action {
                id
                type
                configuration
              }
            }
          }
        }
      }
    }
  }
`;

const flow = { FLOW };

export default flow;
