import { gql } from "@apollo/client";

export const FLOW_ESCROWS = gql`
  query FlowEscrows($where: FlowEscrowWhereInput, $skip: Int, $take: Int) {
    flowEscrows(where: $where, skip: $skip, take: $take) {
      data {
        id
        escrow {
          id
          meta
          endDate
          transactions {
            id
            hex
            base64
            type
          }
          collateral {
            assets {
              usdValue
              id
              type
              content {
                meta
                node
              }
              action {
                id
                type
                configuration
              }
            }
          }
        }
      }
      count
    }
  }
`;

const flowEscrow = { FLOW_ESCROWS };

export default flowEscrow;
