import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation CreateUser($data: UserDataInput) {
    createUser(data: $data) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($where: UserWhereInput, $data: UserDataInput) {
    updateUser(where: $where, data: $data) {
      id
    }
  }
`;

export const SIGN_USER_UP = gql`
  mutation signUp($data: UserWhereInput) {
    signUp(data: $data) @id
  }
`;

const users = { CREATE_USER, UPDATE_USER, SIGN_USER_UP };

export default users;
