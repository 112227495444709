import { useQuery } from "@apollo/client";
import { Col, Popover, Row, Table, Tag } from "antd";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import * as uuid from "uuid";

function List() {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.ORGANIZATIONS, {
    variables: { where: {} },
  });
  const columns = [
    {
      title: LIST.ID,
      key: "id",
      dataIndex: "id",
    },
    {
      title: LIST.NAME,
      key: "name",
      dataIndex: "name",
    },
    {
      title: LIST.KEYS,
      key: "keys",
      render: (_, __) => (
        <Popover title={uuid.v4()} onClick={() => {}}>
          <Tag>{__.name}</Tag>
        </Popover>
      ),
    },
    {
      title: LIST.ACTIONS,
      key: "actions",
      render: (_, __) => {
        return (
          <Row>
            <Col span={2}>
              <AiFillEye
                color={COLORS.C_GRAY_L2}
                size={18}
                onClick={() =>
                  navigate(
                    `${SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.DETAIL}/${__.id}`
                  )
                }
              />
            </Col>
            <Col span={2}>
              <AiOutlineUser
                color={COLORS.C_GRAY_L2}
                size={18}
                onClick={() => navigate(SCREENS.PRIVATE.ADMIN.USERS.LIST)}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  if (loading || error) return "Loading";

  const { organizations } = data;
  return (
    <div className="page__area">
      <Container>
        <Header
          key={cuid()}
          title={LIST.HEADER.TITLE}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[
            {
              text: LIST.HEADER.NEW,
              onClick: () =>
                navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.CREATE),
            },
          ]}
        />
        <Table
          className="custom__table"
          size="small"
          dataSource={organizations.data}
          columns={columns}
          rowKey={"id"}
        />
      </Container>
    </div>
  );
}

export default List;
