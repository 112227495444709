import { useQuery } from "@apollo/client";
import { Image, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  ASSET_TYPES,
  ESCROW_STATUSES,
  TRANSACTION_TYPES,
} from "utils/constants";
import SatoshiBWM from "shared/assets/satoshi-bwn.png";
import { isNil, startCase, get } from "lodash";
import moment from "moment";

const Stakers = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.ORDINAL);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.ESCROWS, {
    variables: {
      where: {
        organization: { id: "1" },
        status: ESCROW_STATUSES.COMPLETED,
        meta: { type: "stake" },
      },
    },
  });
  const columns = [
    {
      title: LIST.ID,
      dataIndex: "id",
      render: (item) => item,
    },
    {
      title: LIST.COLLATERAL,
      dataIndex: "collateral",
      align: "center",
      render: (item) => {
        return item.assets
          .filter((asset) => asset.type === ASSET_TYPES.BTC_UTXO)
          .map((asset) => {
            return <Image src={SatoshiBWM} width={100} key={asset.id} />;
          });
      },
    },
    {
      title: LIST.DROP_FREQUENCY,
      dataIndex: "meta",
      align: "center",
      render: (item) => startCase(get(item, "period", "daily")),
    },
    {
      title: LIST.FLOOR,
      dataIndex: "floor",
      align: "center",
      render: () => <div>$2000</div>,
    },
    {
      title: LIST.STATUS,
      dataIndex: "transactions",
      align: "center",
      render: (item) => {
        const finalTx = item.find(
          (_) => _.type === TRANSACTION_TYPES.FINALIZED
        );
        const statuses = {
          [isNil(finalTx)]: { color: "yellow", text: "Pending" },
          [!isNil(finalTx)]: { color: "green", text: "Staked" },
        };
        const status = statuses[true];
        return <Tag color={status.color}>{status.text}</Tag>;
      },
    },
    {
      title: LIST.EXPIRES,
      dataIndex: "endDate",
      align: "right",
      render: (item) => {
        return moment(parseInt(item)).format("YYYY-MM-DD");
      },
    },
  ];

  if (loading || error) return "Loading";

  const { escrows } = data;

  return (
    <Table
      className="custom__table m-top-24"
      size="small"
      dataSource={escrows.data}
      columns={columns}
      rowKey={"id"}
    />
  );
};

export default Stakers;
