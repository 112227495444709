import gql from "graphql-tag";

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($data: AddressDataInput) {
    createAddress(data: $data) {
      id
    }
  }
`;

const addresses = { CREATE_ADDRESS };

export default addresses;
