import { Input } from "antd";
import classnames from "classnames";
import PropTypes from "prop-types";
import Label from "shared/components/Label";

const Default = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  innerRef,
  suffix,
  ...rest
}) => {
  return (
    <div
      className={classnames("input__wrapper", { "--error": touched && errors })}
    >
      <Label label={label} icon={icon} />
      <div className={`input__wrapper--field`}>
        <Input
          className={classnames({
            "input--borderd--red": touched && errors,
            "disable--input": rest.disabled,
          })}
          placeholder={placeholder}
          ref={innerRef}
          {...rest}
        />
        {suffix && <span className="suffix">{suffix}</span>}
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Default.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Default.defaultProps = {
  label: "",
  placeholder: "",
};
export default Default;
