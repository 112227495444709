import { gql } from "@apollo/client";

const BALANCE_UPDATED = gql`
  subscription BalanceUpdated($where: AccountWhereInput) {
    balanceUpdated(where: $where) {
      debit
      credit
      id
      wallet
    }
  }
`;

const accounts = { BALANCE_UPDATED };

export default accounts;
