import { BsArrowRight } from "react-icons/bs";

const Vertical = ({ name, description, status, onClick }) => {
  return (
    <div className={`card__quick`} onClick={onClick}>
      <div className="card__quick--content">
        <h3>
          {name} <span>{status}</span>
        </h3>
        <p>{description}</p>
      </div>
      <span className="action">
        <BsArrowRight />
      </span>
    </div>
  );
};

export default Vertical;
