import { Upload } from "antd";

const Component = ({
  children,
  handleFileUpload,
  onUpload,
  listType,
  placeholder,
  ...rest
}) => {
  return (
    <Upload
      onChange={async (info) => {
        const fileList = await handleFileUpload(info);
        onUpload(fileList);
      }}
      listType={listType}
      {...rest}
    >
      <div className={"input"}>
        <p className={"input-placeholder"}>{placeholder}</p>
        {children}
      </div>
    </Upload>
  );
};

export default Component;
