import { SCREENS } from "utils/constants";
import Reserves from "./Reserves";
import Welcome from "./Welcome";
import Organization from "./Organization";

const screens = [
  {
    exact: false,
    path: SCREENS.PUBLIC.INDEX,
    element: <Welcome />,
  },
  ...Reserves,
  ...Organization,
];

export default screens;
