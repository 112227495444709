import cuid from "cuid";
import { uniqBy } from "lodash";
import { createContext, useContext, useEffect, useReducer } from "react";
import queries from "utils/api/graphql/queries";
import subscriptions from "./config";
import { SUBSCRIPTION_EVENT_TYPES } from "./constants";

const SUBSCRIBE = "SUBSCRIBE";
const UNSUBSCRIBE = "UNSUBSCRIBE";
const subscriptionsObservers = {};

const initialState = {
  subscriptionsMetadata: {},
};

const store = createContext(initialState);

const { Provider } = store;
const reducer = (state, action) => {
  switch (action.type) {
    case SUBSCRIBE:
      return {
        ...state,
        subscriptionsMetadata: {
          ...state.subscriptionsMetadata,
          [action.payload.query]: [
            ...(Array.isArray(state.subscriptionsMetadata[action.payload.query])
              ? state.subscriptionsMetadata[action.payload.query]
              : []),
            action.payload.metadata,
          ],
        },
      };
    case UNSUBSCRIBE:
      subscriptionsObservers[action.payload.root].unsubscribe();
      return {
        ...state,
        subscriptionsMetadata: {
          ...state.subscriptionsMetadata,
          [action.payload.query]: state.subscriptionsMetadata[
            action.payload.query
          ].filter(({ root }) => {
            return root !== action.payload.root;
          }),
        },
      };
    default:
      return state;
  }
};

const resolvers = {
  [SUBSCRIPTION_EVENT_TYPES.UPDATE]: (
    data,
    variables,
    client,
    query,
    cachedData
  ) => {
    client.writeQuery({
      query: queries[query],
      data: {
        [Object.keys(cachedData)[0]]: {
          ...cachedData[Object.keys(cachedData)[0]],
          ...Object.values(data)[0],
        },
      },
      variables,
    });
  },
  [SUBSCRIPTION_EVENT_TYPES.DELETE]: (
    data,
    variables,
    client,
    query,
    cachedData
  ) => {
    const filtredData = cachedData[Object.keys(cachedData)[0]].data.filter(
      ({ id }) => id !== data[Object.keys(data)[0]].id
    );
    return client.writeQuery({
      query: queries[query],
      data: {
        [Object.keys(cachedData)[0]]: {
          ...cachedData[Object.keys(cachedData)[0]],
          data: filtredData,
          count: filtredData.length,
        },
      },
      variables,
    });
  },
  [SUBSCRIPTION_EVENT_TYPES.ADD]: (
    data,
    variables,
    client,
    query,
    cachedData
  ) => {
    const items = uniqBy(
      [
        data[Object.keys(data)[0]],
        ...cachedData[Object.keys(cachedData)[0]].data,
      ],
      "id"
    );

    const count = items.length;
    client.writeQuery({
      query: queries[query],
      data: {
        [Object.keys(cachedData)[0]]: {
          ...cachedData[Object.keys(cachedData)[0]],
          data: items,
          count,
        },
      },
      variables,
    });
  },
};

const updateCache = ({ data, query, variables, client, type }) => {
  const cachedData = client.readQuery({
    query: queries[query],
    variables,
  });
  resolvers[type](data, variables, client, query, cachedData);
};

const GlobalSubscriptionContext = ({ children, client }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    Object.entries(state.subscriptionsMetadata).forEach(([query, roots]) => {
      roots.forEach(({ variables, root, callback }) => {
        if (Object.keys(subscriptionsObservers).includes(root)) return;
        subscriptions[query].forEach(({ subscriptionQuery, type }) => {
          const observer = client.subscribe({
            query: subscriptionQuery,
            variables,
          });
          const subscription = observer.subscribe({
            next({ data }) {
              if (Object.values(SUBSCRIPTION_EVENT_TYPES).includes(type)) {
                updateCache({
                  data,
                  client,
                  variables,
                  query,
                  type,
                });
              }
              return callback({ data });
            },
            error() {},
          });
          subscriptionsObservers[root] = subscription;
        });
      });
    });
    // eslint-disable-next-line
  }, [state]);

  const unsubscribe = ({ query, root }) =>
    dispatch({ type: UNSUBSCRIBE, payload: { query, root } });

  const subscribe = ({ query, variables, callback = () => {} }) => {
    const root = cuid();
    dispatch({
      type: SUBSCRIBE,
      payload: { query, metadata: { variables, root, callback } },
    });
    return () => unsubscribe({ query, root });
  };

  return <Provider value={{ subscribe }}>{children}</Provider>;
};

export { store, GlobalSubscriptionContext, SUBSCRIBE };

const useSubscription = ({ query, variables, callback }) => {
  const { subscribe } = useContext(store);

  useEffect(() => {
    subscribe({
      query,
      variables,
      callback,
    });
    // eslint-disable-next-line
  }, [JSON.stringify(variables)]);
};

export default useSubscription;
