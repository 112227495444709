import PropTypes from "prop-types";

const Profile = ({ avatar, title, onClick = () => {} }) => {
  return (
    <div className="profile__area" onClick={onClick}>
      <div className="profile__area--media">{avatar}</div>
      <div className="profile__area--content">
        <h4>{title}</h4>
      </div>
    </div>
  );
};

Profile.propTypes = {
  avatar: PropTypes.node,
  onClick: PropTypes.func,
  image: PropTypes.string,
};

Profile.defaultProps = {
  image: "https://via.placeholder.com/90x90",
};

export default Profile;
