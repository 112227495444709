const Simple = ({ title, children }) => {
  return (
    <div className={`card__simple`}>


      <div className="card__simple--head">
        <h3>{title}</h3>
      </div>

      <div className="card__simple--body">
        {children}
      </div>

    </div>
  );
};

export default Simple;
