import { FiCheckCircle } from "react-icons/fi";
import Drawer from "shared/components/Drawer";
import { COLORS } from "shared/style/colors";

const Success = () => {
  return (
    <Drawer title={"Success"}>
      <div className="center__area">
        <FiCheckCircle size={64} color={COLORS.C_SECOND} />
        <div className="content">
          <h2>
            Congratulations, You successfully signed your Vesting Contract
          </h2>
        </div>
      </div>
    </Drawer>
  );
};

export default Success;
